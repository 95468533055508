/* Default styles for larger screens */
.licenseTable {
  width: 100%;
  margin: 0px auto 20px auto;
  
}

.licenseTable tr > th {
  background-color: #2171b9;
  color: white;
  text-align: center;
}

.astrikLegend{
  color: red;
  font-size: 25px;
}

.licenseTable,
td,
th {
  border: 1px solid #bfbfbf;
}

thead[class*="ant-table-thead"] th {
  background-color: hsl(211deg 75% 48%) !important;
}

thead[class*="ant-table-thead"] th.ant-table-selection-column {
  background-color: white;
}

.licenseTable th {
  text-align: center;
  padding: 0.25rem 0.5rem;
}

.licenseTable td {
  padding: 0.25rem 0.5rem;
}

.licenseTable tr {
  height: 40px;
}

.planDetailsModalDiv {
  display: flex;
  align-items: center;
  gap: 20px;
}

.planDetailsModalDiv p:first-child {
  font-size: 18px;
  font-weight: 600;
  width: 170px;
}

.planDetailsModalDiv p:nth-child(2) {
  font-size: 16px;
  font-weight: 400;
}

/* Styles for smaller screens (less than 768px) */
@media (max-width: 768px) {
  .licenseTable th,
  .licenseTable td {
    font-size: 12px;
    padding: 0.15rem 0.3rem;
  }

  .planDetailsModalDiv p:first-child {
    font-size: 16px;
    width: 120px;
  }

  .planDetailsModalDiv p:nth-child(2) {
    font-size: 14px;
  }
}
@media (max-width: 480px) {
  .licenseTable th,
  .licenseTable td {
    font-size: 10px;
    padding: 0.1rem 0.3rem;
  }

  .planDetailsModalDiv p:first-child {
    font-size: 14px;
    width: 100px;
  }

  .planDetailsModalDiv p:nth-child(2) {
    font-size: 12px;
  }
}
