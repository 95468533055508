.bugDescription {
  display: flex;
  flex-direction: row;
  /* width: 600px; */
  /* gap: 90px; */
  font-weight: bold;
}

.cmntlistDiv{
    max-height:550px;
    overflow-y:auto;
    /* margin-top:10px; */
    width: 120%;
}
.cmntInputBox{
     display: flex;
     flex-direction: column;
     margin-top:"10px";
     width: 100%;
     /* margin-left: -20px; */
}


.yourBugsInMobile::-webkit-scrollbar {
    display: none !important;
  }

  .imgsBox {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
      rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    height: "150px";
    padding: "20px";
  }

  .imgsBox::-webkit-scrollbar {
    display: none !important;
  }

  .selectedImgBox::-webkit-scrollbar {
    display: none !important;
  }

  .yourBugsInMobile::-webkit-scrollbar {
    display: none !important;
  }

  .imgsBox {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
      rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    height: "150px";
    padding: "20px";
  }

  .userAvatar{
    object-fit: fill;
  }

  .imgsBox::-webkit-scrollbar {
    display: none !important;
  }

  .selectedImgBox::-webkit-scrollbar {
    display: none !important;
  }

  .clientListItem {
    cursor: pointer;
  }
  
  .clientListItem:hover {
    background-color: rgba(42, 82, 196, 0.1);
    /* color: #fff !important; */
  }

  .filterDiv{
    display:flex;
    justify-content:"space-evenly";
  }

@media screen and (max-width: 1350px) {
    .bugDescription {
        display: flex;
        flex-direction: column;
        gap: 0px;
        padding-top: 55px;
        margin-left: -100px;
      }
      .bugDescription > div{
        margin-top: -10px;
      }
      .content{
        border:"1px solid red"
      }
}

@media screen and (max-width: 480px) {
    .bugId {
      margin: 0px 0px 0px -25px;
      display: flex;
      justify-self: flex-start;
      width: 150%;
      padding-top: 10px;
      cursor: "default";
    }
    .bugDescription {
      display: flex;
      flex-direction: column;
       margin-top: 40px;
      gap: 0px;
      padding-top: 10px;
      margin-left: -70px;
    }
    .bugDescription > div{
      margin-top: -10px;
    }
    .imgsBox {
      display: grid;
      grid-template-columns: repeat(2 100px);
    }
    .content{
        border:"1px solid red"
      }
  }

@media screen and (max-width: 450px) {
  .bugId {
    margin: 0px 0px 0px -25px;
    display: flex;
    justify-self: flex-start;
    width: 150%;
    padding-top: 10px;
    cursor: "default";
  }
  .bugDescription {
    display: flex;
    flex-direction: column;
    gap: 0px;
    padding-top: 60px;
    margin-left: -70px;
    border:"1px solid red"
  }
  .bugDescription > div{
    margin-top: -10px;
  }
  .bugTitle {
    margin-top: -60px;
  }
  .userAvatar {
    margin-top: -10px;
  }
}



@media screen and (max-width: 400px) {
  .bugId {
    margin: 0px 0px 0px -25px;
    display: flex;
    justify-self: flex-start;
    width: 150%;
    padding-top: 10px;
    cursor: "default";
  }
  .bugDescription {
    display: flex;
    flex-direction: column;
    gap: 0px;
    padding-top: 50px;
    margin-left: -70px;
  }
  .bugDescription > div{
    margin-top: -10px;
  }
  .bugTitle {
    margin-top: -60px;
  }

  .cmntInputBox{
     display: flex;
     flex-direction: column;
     margin-top:"10px";
     /* margin-left: -20px; */
     align-items: center;
    box-sizing: border-box;
    padding: 10px;
    }

    .cmntInputBox button{
        margin-top: 5px;
    }

}

@media screen and (max-width:749px){
  .filterDiv{
    display:flex;
    flex-direction: column;
    align-items: center;
  }
}

/* @media screen and (max-width: 1400px) {
  .bugDescription {
    display: flex;
    flex-direction: column;
    gap: 0px;
    padding-top: 50px;
    margin-left: -100px;
  }
} */

