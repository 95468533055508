.uploadButtonContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
}

.upnlodButton {
  display: none;
  border: 1px dashed gray;
  align-items: center;
  text-align: center;
  justify-content: center;
  background-color: rgb(243, 241, 241);
  width: 150px;
  height: 90px;
}
.upnlodButton:hover {
  border: 1px dashed rgb(0, 74, 104);
}

.picSize {
  width: 170px;
  height: 170px;
}

.uploadedImage {
  size: 200;
  width: 171px;
  height: 171px;
}
@media only screen and (max-device-width: 480px) {
  .uploadButtonContainer {
    grid-template-columns: repeat(2, 1fr);
  }

  .uploadButtonbigscreen {
    display: none;
  }
  .upnlodButton {
    display: flex;
  }
  .uploadedImage {
    size: 200px;
    font-size: 50px;
    width: 120px;
    height: 120px;
  }
}
