.custom-dragger {
  width: 20px !important;
  height: 20px !important;
  padding: 0 !important;
  border: 0 !important;
  background: transparent !important;
  box-shadow: none !important;
  margin-right: 20px;
}

.custom-drag-icon {
  font-size: 20px !important;
}
.ant-upload {
  box-sizing: border-box;
  margin: 0;
  display: flex;
  padding: 0;
  color: rgba(0,0,0,.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  outline: 0;
  height: 20px;
  margin-right: 20px;
}

.pdfspan:hover{
background-color: rgb(219, 237, 242);
}
table {
width: 100%;
border-collapse: collapse;
}

th, td {
border: 1px solid #000;
padding: 8px;
}

.truncate {
white-space: nowrap !important;
overflow: hidden !important;
text-overflow: ellipsis !important;
}
.search-input .ant-input-search-button {
display: none;
}