

.closeIcon {
  margin-left: -25px;
  position: relative;
  z-index: 10;
  font-size: 10px !important;
  cursor: pointer;
  background-color: rgb(203, 200, 200);
  border-radius: 100px;
  width: 20px;
  height: 20px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  margin-top: 6px;
}

.css-2b097c-container {
  width: 100% !important;
}

.lbl > div .css-1wy0on6 {
  display: none;
}

.documentFormContainer {
  height: calc(100vh - 7.8rem);
}

/* custum css for libray input  */
.newNumberInput {
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    padding: 6px 8px;
    font-size: 14px;
    transition: border-color 0.3s;
  }
 .newNumberInput::placeholder {
    color: rgba(0, 0, 0, 0.25); 
  }

  .newNumberInput:focus {
    border-color: #1890ff;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }

.phoneInput {
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    padding: 6px 8px;
    font-size: 14px;
    transition: border-color 0.3s;
  }

  .phoneInput:focus {
    border-color: #1890ff;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }
