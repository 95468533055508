.draftListing:checked {
  background-color: #d0dde9 !important;
}

.photo_notes {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100px;
  padding-top: 10px;
  font-size: 20px;
}

.clientListContainer {
  height: "calc(100vh - 195px)";
}

.documentContainerSmallScreen {
  display: none;
}
.documentContainerLargeScreen {
  display: block;
}
@media only screen and (max-device-width: 480px) {
  .documentContainerSmallScreen {
    display: block;
  }
  .documentContainerLargeScreen {
    background-color: red;
    display: none;
  }
}
