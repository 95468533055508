
.agentDetailsModalContent {
  display: flex;
  align-items: center;
  gap: 30px;
  color: grey;
  margin-bottom: 40px;
}
.agentDetailsModalContent .userDetails {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.agentDetailsModalContent .userDetails .userDetailsFlex {
  width: 93%;
  max-width: 93%;
  display: flex;
  align-items: center;
  gap: 20px;
}
