.safetdrive{
     height: calc(100vh - 280px);
}

.paginationContainer{
  width: 100%;
}

@media all and (max-width:799px) {
  .safetdrive{
    height:calc(100vh - 340px)
  }
}

  @media (max-height: 670px)and (orientation: portrait) {
    .safetdrive{
     height: calc(100vh - 320px)
   }
}
@media (max-height: 780px)and (min-height: 671px)and (orientation: portrait) {
     .safetdrive{
      height: 55vh;
    }
 }

 @media (max-width:1350px) {
  .safetdrive{
    width: 1000px;
  }
  .paginationContainer{
    width: 1000px;
  }
 }
