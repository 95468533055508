.agentLogName {
    color: rgb(96, 96, 96);
    font-size: 1.2rem;
    font-weight: 600;
    font-family: "Roboto", sans-serif;
    letter-spacing: 0.4px;
}

.logDescription {
    color: rgb(143,143,143);
    font-size: 1rem;
    font-weight: 600;
    line-height: 23px;
    font-family: "Roboto", sans-serif;
    letter-spacing: 0.4px;
}

.idsLog {
    color: rgb(96, 96, 96);
    font-size: 1rem;
    font-weight: 600;
    font-family: "Roboto", sans-serif;
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.statusSection {
    color: rgb(96, 96, 96);
    font-size: 1rem;
    font-weight: 600;
    font-family: "Roboto", sans-serif;
    letter-spacing: 0.4px;
}

.timeStampSection {
    color: rgb(96, 96, 96);
    font-size: 0.9rem;
    font-weight: 600;
    font-family: "Roboto", sans-serif;
}