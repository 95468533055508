.alignBackground {
  background-image: url(https://i.pinimg.com/originals/bb/98/55/bb9855e4ce31410e9289453ddb447ceb.jpg);
  background-position: top center;
  background-size: cover;
  width: 100%;
  height: 100%;
  max-height: 100%;
  background-repeat: no-repeat;
  background-color: #444;
  position: relative;
  overflow-x: hidden;
}

.alignBackground:after {
  content: "";
  background-color: #032345;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.5;
}

.alignForhead {
  position: absolute !important;
  opacity: 1.5;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 150px;
}

.custFormH1 {
  font-size: 42px;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  color: #404b51;
  line-height: 55px;
  margin-bottom: -5px;
}

.btnPrimary {
  display: block !important;
  width: 100%;
  /* margin-bottom: 15px; */
  text-transform: uppercase;
  /* padding: 10px 20px; */
  border-radius: 30px !important;
  margin-bottom: 1rem;
}

.custFormH2 {
  font-size: 20px;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  color: #8e959a;
  line-height: 37px;
  margin-bottom: 10px;
}

.textAlignment {
  width: 100%;
  min-width: 0px;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: rgb(255, 255, 255);
  background-image: none;
  border: 1px solid rgb(217, 217, 217);
  border-radius: 2px;
  transition: all 0.3s ease 0s;
  cursor: pointer;
}

.textAlignment:hover {
  outline: #18ffe982 !important;
}
#licenseBtn {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: #178dfa;
  font-weight: bolder;
  background: white;
  padding: 20px 15px;
  border: 1px solid silver;
  border-radius: 10px;
  z-index: 555;
}

.formaccnt .lbl span {
  font-size: 20px;
  color: #8e959a;
}

.formaccnt {
  display: flex !important;
  flex-direction: row !important;
  align-items: center;
  /* border: 2px solid red; */
}

.site-layout-background li {
  font-size: 20px;
}

.profilephoto {
  width: 150px;
  height: 200px;
}

.profilephoto img {
  border: 4px solid #f0f2f5;
  display: inline-block;
  object-fit: contain;
}

.iconsize {
  width: 18px;
}
.sider-children {
  height: 49px;
  line-height: 49px;
}
.genralText {
  font-size: 18px;
  cursor: pointer;
  width: fit-content;
}
.genralText:hover {
  color: #178dfa;
}
.officeGeneralText {
  font-size: 18px;
  width: fit-content;
}
.account{
  padding:0px;
  width:100%

}
.licensetable{
  width:150%
}
.cancelButton{
  margin-left: 100px;
}
.cancelLicenseId{
  margin-left: 60px;
}
@media (max-width: 1024px) {
  .licensetable{
    width:120%
  }
}
@media (max-width: 1024px) {
  .licensetable{
    width:118%
  }
  .account{
    padding:0px;
      width:150%
 }
}

@media (max-width: 480px) {
  .account{
    padding:0px;
      width:300%
 }
 .cancelButton{
  margin-left: 30px;
}
.cancelLicenseId{
  margin-left: 20px;
}
}

@media (min-width:1025px) and (max-width: 1243px) {
  .account{
    padding:0px;
      width:120%
 }
}

@media (min-width:481px) and (max-width: 679px) {
  .account{
    padding:0px;
      width:255%
 }
}

@media (min-width:680px) and (max-width: 827px) {
  .account{
    padding:0px;
      width:190%
 }
}

@media (min-width:375px) and (max-width: 410px) {
  .account{
    padding:0px;
      width:340%
 }
}