.responsivetabs{
  width:100vw;
  height:calc(100vh - 8.4rem);
  font-size: 10px;
}
.firsttab{
font-weight:bold;
    padding: 20px;
    width: 100%;
    text-align: center;
    margin: auto;
    color: rgb(76,170,255);
    font-size: 21px;
   
}
.action{
  width: 20.33%;
   text-align:center;
   font-size:20px;
   font-weight:bold
}
.hoverClass{
display: flex;
margin: 5px;
border-radius: "5px";
cursor: pointer;
align-items: center;
}
.hoverClass:hover{
background-color: rgb(132, 187, 238);
}
.action2{
  
  width: 25.33%;
   text-align:center;
   font-size:18px;
   font-weight:bold
}
.action3{
  
  width: 50.33%;
   text-align:center;
   font-size:18px;
   font-weight:bold
}
.document{
  width: 36.33%;
   text-align:center;
   font-size:20px;
   font-weight:bold
}
.description{
  width: 40.33%;
  text-align:center;
  font-size:20px;
  font-weight:bold 
}
.arrowStyle {
  width: 0;
  height: 0;
  border-left: 25px solid yellow;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
}

.tab2{
   width: 25.33%;
   text-align: "center";
   font-size:"18px";
   font-weight:"bold" 
}
.arrowStyle2 {
  width: 0;
  height: 0;
  border-left: 25px solid lightgray;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
}
.conname{
  width: 25.33%;
   text-align: "center";
   font-size:"18px";
   font-weight:"bold" 
}
.contract_name{
  font-size: 15px;
  text-align: left;
  width: 60.33%;
}
.timestamp{
  font-size:15px
}

.readOnlyFullAddress {
  font-weight: bold;
  font-size: large;
  text-align: center;
}

@media screen and (min-width: 481px) and (max-width: 851px) {
  .readOnlyFullAddress {
    font-size: medium;
  }
}

@media only screen and (max-device-width: 480px){
  .firsttab{
    font-weight:bold;
        padding: 10px;
        width: 100%;
        text-align: center;
        margin: auto;
        color: rgb(76,170,255);
        font-size: 15px;
    
    }
  .responsivetabs{
      width:100%;
      border:"1px solid red";
      height:calc(100vh - 8.4rem);
      font-size:"10px"
  }
  .tabname{
  font-size: 12px;
 }
 .action{
  width: 20.33%;
   text-align:center;
   font-size:15px;
  font-weight: bold;
}
.action2{
  
  width: 25.33%;
   text-align:center;
   font-size:15px;
   font-weight:bold
}
.action3{
  
  width: 50.33%;
   text-align:center;
   font-size:15px;
   font-weight:bold
}
.document{
  width: 36.33%;
   text-align:center;
   font-size:15px;
   font-weight:bold
}
.description{
  width: 40.33%;
  text-align:center;
  font-size:15px;
  font-weight:bold 
}
.arrowStyle {
 
  border: 15px solid transparent;
border-left: 15px solid yellow;
}
.arrowStyle2 {
 
  border: 15px solid transparent;
border-left: 15px solid lightgray;
}
 .documentsigning{
  display: "flex";
  width: "100%";
  background-color: " #0078d4";
  color: "white";
  font-size: "15px";
  padding: "5px",
}
.contractName{
  font-size: 12px;
}
.contract_name{
  font-size: 12px;
  text-align: left;
  width: 60.33%;
}
.timestamp{
  font-size:12px
}
.from{
  width: 25.33%;
   text-align: "center";
   font-size:"15px";
   font-weight:"bold" 
}
.conname{
  width: 25.33%;
   text-align: "center";
   font-size:"15px";
   font-weight:"bold" 
}
  .readOnlyFullAddress {
    font-size: small;
  }
}