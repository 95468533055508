.customForm .ant-input::placeholder {
    color: #69a3f5 !important; /* Custom blue placeholder color */
  }
  .ant-checkbox-wrapper .ant-checkbox-inner {
    border-color: gray;
      /* Change border color to red */
  }
  @media (min-width: 601px) and (max-width: 1024px) {
    .ant-checkbox-wrapper .ant-checkbox-inner {
        font-size: 14px; /* Adjust font size */
        width: 16px; /* Adjust checkbox size */
        height: 16px; /* Adjust checkbox size */
    }
}
@media (max-width: 600px) {
  .ant-checkbox-wrapper .ant-checkbox-inner {
    font-size: 12px; /* Adjust font size */
    width: 12px; /* Adjust checkbox size */
    height: 12px;
     /* Adjust checkbox size */
}
}