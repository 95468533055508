.account-site-layout-background {
    position: relative;
}

.account-site-layout-background .ant-layout-sider-trigger {
    background: #085191;
    /* background: rgb(58, 116, 182); */
    font-weight: bold;
    position: absolute;
    top: 550%;
    transition: all .3s;
}