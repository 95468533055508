#parent-scroll-div .ant-list-item-meta-description {
  margin-top: -5px;
}
#parent-scroll-div .ant-list-item-meta-title {
  margin-top: -1px;
}
.clientListContainer1 {
  height: 100%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  background-color: #fff;

  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  overflow-x: hidden;
  overflow-y: hidden;
  padding: 0.4rem;
}
.clientListContainer {
  height: 100%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  background-color: #fff;

  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0.4rem;
}
/* when searchInput box true */
.clientListBox {
  height: calc(100vh - 240px);
}
/* when searchInput box not visible */
.clientListBoxForSmallScreen {
  height: calc(100vh - 195px);
}
@media only screen and (max-device-width: 480px) {
  /* .clientListBox {
    height: 37vh;
  } */
  /* .clientListBoxForSmallScreen {
    height: 42vh;
  } */
  /* .clientListContainer {
    height: 52vh;
  } */
}
/* Documentry Root CSS */
.documentaryTabPane {
  font-size: 1.2rem;
  color: red;
}

/* button css  */
.icon-button {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .icon {
    opacity: 0;
    display: inline-block;
    transition: opacity 0.3s ease;
  }

  .visible {
    opacity: 1;
  }

  .hidden {
    opacity: 0;
  }

#myDiv .ql-container .ql-editor .ql-font-arial {
    font-family: 'Arial', sans-serif;
    content: 'Ariel';
}

#myDiv .ql-container .ql-editor .ql-font-georgia {
    font-family: 'Georgia', serif;
    content: 'Georgia';
}

#myDiv .ql-container .ql-editor .ql-font-helvetica {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    content: 'Helvetica';
}

@media only screen and (max-device-width: 400px) {
  /* .clientListBox {
    height: 35vh;
  } */
}
