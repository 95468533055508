.calendar_page_DateBox{
    border: 1px solid red !;
  }

  @media only screen and (max-device-width: 480px) {
    .calendar_page_DateBox{
        text-align: center;
        margin-top: 10px;
        position: absolute;
        top: 5px;
        left: 150px;
        font-size: small;
    }
  }

  @media only screen and (max-device-width: 650px) and (min-device-width: 480px) {
    .calendar_page_DateBox{
        text-align: center;
        margin-left: -5px;
        font-size: large;
    }
  }
