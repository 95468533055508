.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #1890ff;
  font-weight: unset !important;
}
/*------------ overRiding the default ant-design preview image position thing---------- */
.ant-image-preview-operations-operation {
  padding: 14px !important;
}
.ant-image-preview-body ul {
  padding-right: 3rem !important;
  background: #113e69fc !important;
}
.ant-image-preview-body {
  background: #178dfa00 !important;
}
/* -----------------------------image css ends----------------------------------------- */
.ant-card-body {
  padding: 0;
}

.ant-collapse-header {
  /* padding: 12px 80px !important; */
  color: rgb(0, 0, 0) !important;
  /* background-color: white; */
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 1px;
}

.ant-form-item-label > label {
  font-size: 18px !important;
  color: rgba(0, 0, 0, 0.45) !important;
  font-weight: bold !important;
}

.newNumberInput {
  box-sizing: border-box;
  margin: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
}
.ant-image-mask {
  width: 100%;
  height: 100%;
}
.ant-image-img .Client_profileimg__1U8V9 {
  width: 50%;
  height: 50%;
}

.ant-collapse-header {
  font-size: 1.2rem !important;
  text-transform: uppercase !important;
  color: rgba(0, 0, 0, 0.45) !important;
  font-weight: 600 !important;
}
.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
  padding: 12px 20px 12px 20px !important;
}
.ant-collapse-icon-position-right
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  right: 16px !important;
  top: 7px !important;
  left: auto;
  font-size: 18px !important;
}
.ant-tabs-tab {
  font-size: 1.2rem !important;
  text-transform: uppercase !important;
  color: rgba(0, 0, 0, 0.45) !important;
  font-weight: 700 !important;
}

.ant-collapse-header:hover {
  color: rgb(27, 164, 238) !important;
}

.custom {
  background-color: #d3d3d3;
}

.custom2 {
  background-color: white;
}

.ant-modal {
  width: "720px" !important;
  top: "70px" !important;
}

.ant-modal-content {
  height: "500px" !important;
}

.ant-modal-body {
  height: "380px" !important;
}

.draftModalDiv {
  float: right;
  width: 160px;
}

.draftDocumentButton {
  background-color: "#fff" !important;
  font-size: "20px" !important;
  /* padding: 5px !important; */
  font-weight: 700 !important;
  width: 160px !important;
  border-radius: 5px !important;
  box-shadow: -1px 1px 2px 1px rgb(0, 0, 0) !important;
  color: black;
}

.ant-list-item:hover {
  color: "red" !important;
  /* position: relative;
  padding-left: 3em;
  margin: 0.45em 0;
  list-style: none;
  line-height: 1.8em;
font-size:20px; */
  cursor: pointer;
  /* -webkit-transition: all 0.2s ease-in-out;
transition: all 0.2s ease-in-out; */
}

/* .mainDiv{
  display: flex !important; 
  justify-content: space-around !important;
  border-color: green !important;
} */

.ant-form-item-label {
  margin-bottom: 4px !important;
  color: rgba(0, 0, 0, 0.85) !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 1.5715 !important;
  /* background-color: aqua; */
}

.ant-list-item-meta-content:focus {
  background-color: blue !important;
  color: white !important;
}

.ant-menu-item-active {
  background-color: #99caf7 !important;
  /* color: #d3d3d3 !important; */
}

.ant-timeline-item-content {
  font-size: 18px !important;
}

.ant-timeline-item-head-blue,
.ant-timeline-item-head {
  width: 15px !important;
  height: 15px !important;
  left: -4px !important;
  top: 1px !important;
}

.ant-timeline-item-content {
  color: rgba(0, 0, 0, 0.45) !important;
  font-weight: bold !important;
}

h3.ant-typography,
.ant-typography h3 {
  color: rgba(0, 0, 0, 0.45) !important;
  font-size: 22px !important;
  text-transform: uppercase !important;
}

.formaccnt .lbl label {
  font-size: 18px;
  color: rgba(0, 0, 0, 0.45);
  font-weight: bold;
}

.ant-list-header,
.ant-list-footer {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
}



.ant-list-item-meta-title {
  font-size: 16px !important;
  font-weight: 700 !important;
  color: rgba(0, 0, 0, 0.45) !important;
}



.formaccnt .lbl1 span {
  font-size: 18px;
  color: rgba(0, 0, 0, 0.45);
  /* width: 90%;
    display: block; */
}

.formaccnt .lbl1 label {
  font-size: 18px;
  color: rgba(0, 0, 0, 0.45);
  font-weight: bold;
}

.extraDrawer .ant-form-item-required {
  font-size: 18px !important;
  color: rgba(0, 0, 0, 0.45) !important;
  font-weight: bold !important;
}

.extraDrawer {
  font-size: 18px !important;
  color: rgba(0, 0, 0, 0.45) !important;
  font-weight: bold !important;
}

.ant-form-item-label > label::after {
  content: "" !important;
}

.formaccnt .lbl1 label::before {
  position: absolute !important;
  right: -5px;
  z-index: 9999;
}

.search_label {
  font-size: 18px !important;
  color: rgba(0, 0, 0, 0.45) !important;
  font-weight: bold !important;
}

.ant-typography h3 {
  color: rgba(0, 0, 0, 0.45) !important;
}

.ant-form-item-label {
  text-align: left !important;
}

.ant-layout-footer {
  color: #fff !important;
  font-size: 14px;
  background: rgb(8, 81, 145) !important;
}

.offer_screenForm {
  padding: 1rem;
  height: calc(100vh - 7.8rem);
  overflow: auto;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}

.landing_screenForm {
  padding: 1rem;
  height: calc(100vh - 225px);
  overflow: auto;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}

.offer_draft_docs {
  padding: 1rem;
  height: calc(100vh - 7.8rem);
  overflow: auto;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}

.ant-tabs-tabpane .ant-tabs-tabpane-active {
  display: flex !important;
}

.ant-layout {
  background-color: #fff !important;
}

/* .ant-list-item-action{
  transform: rotate(90deg) !important
} */
.ant-drawer-content-wrapper {
  width: 500px !important;
}

.reminder ul .ant-list-item-action {
  transform: rotate(0deg) !important;
}

.ant-avatar-lg.ant-avatar-icon {
  width: 35px;
  height: 35px;
}

.ant-menu-inline > .ant-menu-item {
  display: flex !important;
  align-items: center !important;
}

.ant-badge-count {
  position: absolute !important;
  top: 3px !important;
  right: 32px !important;
}

.ant-modal-content {
  background-color: "none" !important;
}

.ant-card-body {
  /* padding: 24px; */
  display: flex !important;
}
.ant-upload {
  font-size: 50px !important;
}
.ant-upload.ant-upload-select-picture-card {
  width: 120px !important;
  height: 120px !important;
}

.card {
  margin: "5px";
  padding: "10px";
  display: "flex";
  flex-direction: "column";
}

.card Image {
  height: 70px !important;
  width: 90px !important;
}

.ant-image-preview-img {
  max-height: 75% !important;
}

.ant-collapse-content-box {
  padding: 0px !important;
}

.ant-notification-notice-content div {
  width: 100% !important;
}

.ant-list-item-meta-avatar {
  background-color: #99caf7 !;
}
.ant-notification-notice-description {
  margin-left: 0 !important;
  transition: 0.3s !important;
  height: calc(100vh - 100px) !important;
  overflow: auto !important;
}

.ant-notification-notice-content .ant-list-item-meta-avatar {
  width: 8% !important;
}

.ant-notification-notice-content .ant-list-item-meta-content {
  width: 80% !important;
}

.ant-notification-notice-content .clickFlag {
  width: 8% !important;
}

.ant-list-item .Client_clientListItem__1Xc1G:active {
  background-color: aquamarine !important;
}

.landingPageFont {
  text-align: justify;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 600;
}

.ant-list-item-meta-title > a {
  color: rgba(0, 0, 0, 0.45) !important;
}

.landing_page {
  display: flex;
}

.modalStyle .ant-modal-close-x {
  display: none !important;
}

.modalStyle .ant-modal-header {
  padding: 0 !important;
  color: rgba(0, 0, 0, 0.85) !important;
  background: #fff !important;
  border-bottom: 0 !important;
  border-radius: 5px 5px 0 0 !important;
}

.modalStyle .ant-modal-header {
  padding: 0 !important;
  color: rgba(0, 0, 0, 0.85) !important;
  background: #fff !important;
  border-bottom: 0 !important;
  border-radius: 5px 5px 0 0 !important;
}
.templateBlocks div {
  margin-bottom: 0px;
}
.landing_page_date {
  position: absolute;
  top: 15px;
  left: 41%;
  font-size: 24px;
  font-weight: bold;
  color: #1d4397d1;
}
/*------------ CSS for Calendar page date starts---------- */
.calendar_page_DateBox {
  position: absolute;
  top: 15px;
  left: 35.5%;
  font-size: 24px;
  font-weight: bold;
  color: #1d4397d1;
  /* border: 2px solid red; */
}
.calendar_page_DateBox .calendarDate {
  display: inline-flex;
  justify-content: center;
  width: 310px;
}
@media only screen and (max-device-width: 1366px) {
  .calendar_page_DateBox {
    font-size: 22px;
  }
}
@media only screen and (max-device-width: 768px) {
  .calendar_page_DateBox {
    margin-left: 40%;
  }
}
@media only screen and (max-device-width: 608px) {
  .calendar_page_DateBox {
    font-size: 20px;
  }
  .calendar_page_DateBox .calendarDate {
    width: fit-content;
  }
}
@media only screen and (max-device-width: 450px) {
  .calendar_page_DateBox {
    font-size: 16px;
  }
}
@media only screen and (max-device-width: 400px) {
  .calendar_page_DateBox {
    /* display: none; */
    font-size: 15px;
  }
}
/*------------ CSS for Calendar page date ends ---------- */
.navbarMenu .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
  margin: 0px 15px !important;
  margin-top: -1px;
  margin-bottom: 0;
  padding: 0 !important;
}

.draftDocumentOffer .ant-avatar-string {
  line-height: 37px !important;
}

.offer-clone .ant-modal-close {
  top: 15px !important;
  right: 15px !important;
}

.offer-clone p {
  text-align: center;
  text-transform: uppercase;
}

.ant-modal-close-x {
  display: block;
  width: 30px !important;
  height: 0px !important;
  font-size: 16px;
  font-style: normal;
  line-height: 25px !important;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
}

.ant-page-header-heading-title {
  color: #8c8c8c !important;
  font-weight: 600 !important;
}

@media only screen and (max-device-width: 1366px) {
  .signupForm .ant-form-item {
    margin-bottom: 5px;
  }
}

/* ----------- iPad Pro 10.5" ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 820px) and (max-device-width: 1180px) and (-webkit-min-device-pixel-ratio: 2) {
  /* .offer_screenForm {
    height: calc(100vh - 800px);
  } */
  .offer_draft_docs {
    height: calc(100vh - 600px);
  }
  .landing_page {
    display: flex;
 
  }
  .landing_page_date {
    position: unset;
  }
}

/* ----------- iPad 3, 4 and Pro 9.7" ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
  /* .offer_screenForm {
    height: calc(100vh - 700px);
  } */
  .offer_draft_docs {
    height: calc(100vh - 550px);
  }
  .landing_page {
    display: flex;

  }
  .landing_page_date {
    position: unset;
  }
}

/* Portrait and Landscape */
@media only screen and (max-device-width: 480px) {
  .offer_screenForm {
    height: calc(100vh - 339px);
  }
  .offer_draft_docs {
    height: calc(100vh - 451px);
    margin-bottom: 73px;
  }
  .landing_page {
    display: flex;
  
  }
  .landing_page_date {
    position: unset;
  }
}

.border-bottom {
  border-bottom: 0px !important;
}

.ant-card-body {
  padding: 0;
}

.ant-collapse-header {
  /* padding: 12px 80px !important; */
  color: rgb(0, 0, 0) !important;
  /* background-color: white; */
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 1px;
}

.ant-form-item-label > label {
  font-size: 18px !important;
  color: rgba(0, 0, 0, 0.45) !important;
  font-weight: bold !important;
}

.newNumberInput {
  box-sizing: border-box;
  margin: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
}
.ant-image-mask {
  width: 100%;
  height: 100%;
}
.ant-image-img .Client_profileimg__1U8V9 {
  width: 50%;
  height: 50%;
}

.ant-collapse-header {
  font-size: 1.2rem !important;
  text-transform: uppercase !important;
  color: rgba(0, 0, 0, 0.45) !important;
  font-weight: 600 !important;
}
.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
  padding: 12px 20px 12px 20px !important;
}
.ant-collapse-icon-position-right
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  right: 16px !important;
  top: 7px !important;
  left: auto;
  font-size: 18px !important;
}
.ant-tabs-tab {
  font-size: 1.2rem !important;
  text-transform: uppercase !important;
  color: rgba(0, 0, 0, 0.45) !important;
  font-weight: 700 !important;
}

.ant-collapse-header:hover {
  color: rgb(27, 164, 238) !important;
}

.custom {
  background-color: #d3d3d3;
}

.custom2 {
  background-color: white;
}

.ant-modal {
  width: "720px" !important;
  top: "70px" !important;
}

.ant-modal-content {
  height: "500px" !important;
}

.ant-modal-body {
  height: "380px" !important;
}

.draftModalDiv {
  float: right;
  width: 160px;
}

.draftDocumentButton {
  background-color: "#fff" !important;
  font-size: "20px" !important;
  /* padding: 5px !important; */
  font-weight: 700 !important;
  width: 160px !important;
  border-radius: 5px !important;
  box-shadow: -1px 1px 2px 1px rgb(0, 0, 0) !important;
  color: black;
}

.ant-list-item:hover {
  color: "red" !important;
  /* position: relative;
  padding-left: 3em;
  margin: 0.45em 0;
  list-style: none;
  line-height: 1.8em;
font-size:20px; */
  cursor: pointer;
  /* -webkit-transition: all 0.2s ease-in-out;
transition: all 0.2s ease-in-out; */
}

/* .mainDiv{
  display: flex !important; 
  justify-content: space-around !important;
  border-color: green !important;
} */

.ant-form-item-label {
  margin-bottom: 4px !important;
  color: rgba(0, 0, 0, 0.85) !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 1.5715 !important;
  /* background-color: aqua; */
}

.ant-list-item-meta-content:focus {
  background-color: blue !important;
  color: white !important;
}

.ant-menu-item-active {
  background-color: #99caf7 !important;
  /* color: #d3d3d3 !important; */
}

.ant-timeline-item-content {
  font-size: 18px !important;
}

.ant-timeline-item-head-blue,
.ant-timeline-item-head {
  width: 15px !important;
  height: 15px !important;
  left: -4px !important;
  top: 1px !important;
}

.ant-timeline-item-content {
  color: rgba(0, 0, 0, 0.45) !important;
  font-weight: bold !important;
}

h3.ant-typography,
.ant-typography h3 {
  color: rgba(0, 0, 0, 0.45) !important;
  font-size: 22px !important;
  text-transform: uppercase !important;
}

.formaccnt .lbl label {
  font-size: 18px;
  color: rgba(0, 0, 0, 0.45);
  font-weight: bold;
}

.ant-list-header,
.ant-list-footer {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
}



.ant-list-item-meta-title {
  font-size: 16px !important;
  font-weight: 700 !important;
  color: rgba(0, 0, 0, 0.45) !important;
}

/* .formaccnt .lbl label::before { */
/* position: absolute !important; */
/* right: -15px; */
/* } */

.formaccnt .lbl1 span {
  font-size: 18px;
  color: rgba(0, 0, 0, 0.45);
  /* width: 90%;
    display: block; */
}

.formaccnt .lbl1 label {
  font-size: 18px;
  color: rgba(0, 0, 0, 0.45);
  font-weight: bold;
}

.extraDrawer .ant-form-item-required {
  font-size: 18px !important;
  color: rgba(0, 0, 0, 0.45) !important;
  font-weight: bold !important;
}

.extraDrawer {
  font-size: 18px !important;
  color: rgba(0, 0, 0, 0.45) !important;
  font-weight: bold !important;
}

.ant-form-item-label > label::after {
  content: "" !important;
}

.formaccnt .lbl1 label::before {
  position: absolute !important;
  right: -5px;
  z-index: 9999;
}

.search_label {
  font-size: 18px !important;
  color: rgba(0, 0, 0, 0.45) !important;
  font-weight: bold !important;
}

.ant-typography h3 {
  color: rgba(0, 0, 0, 0.45) !important;
}

.ant-form-item-label {
  text-align: left !important;
}

.ant-layout-footer {
  color: #fff !important;
  font-size: 14px;
  background: rgb(8, 81, 145) !important;
}

.ant-tabs-tabpane .ant-tabs-tabpane-active {
  display: flex !important;
}

.ant-layout {
  background-color: #fff !important;
}

/* .ant-list-item-action{
  transform: rotate(90deg) !important
} */
.ant-drawer-content-wrapper {
  width: 500px !important;
}

.reminder ul .ant-list-item-action {
  transform: rotate(0deg) !important;
}

.ant-avatar-lg.ant-avatar-icon {
  width: 35px;
  height: 35px;
}

.ant-menu-inline > .ant-menu-item {
  display: flex !important;
  align-items: center !important;
}

.ant-badge-count {
  position: absolute !important;
  top: 3px !important;
  right: 32px !important;
}

.ant-modal-content {
  background-color: "none" !important;
}

.ant-card-body {
  /* padding: 24px; */
  display: flex !important;
}
.ant-upload {
  font-size: 50px !important;
}
.ant-upload.ant-upload-select-picture-card {
  width: 120px !important;
  height: 120px !important;
}

.card {
  margin: "5px";
  padding: "10px";
  display: "flex";
  flex-direction: "column";
}

.card Image {
  height: 70px !important;
  width: 90px !important;
}

.ant-image-preview-img {
  max-height: 75% !important;
}

.ant-collapse-content-box {
  padding: 0px !important;
}

.ant-notification-notice-content div {
  width: 100% !important;
}

.ant-list-item-meta-avatar {
  background-color: #99caf7 !;
}
.ant-notification-notice-description {
  margin-left: 0 !important;
  transition: 0.3s !important;
  height: calc(100vh - 100px) !important;
  overflow: auto !important;
}

.ant-notification-notice-content .ant-list-item-meta-avatar {
  width: 8% !important;
}

.ant-notification-notice-content .ant-list-item-meta-content {
  width: 80% !important;
}

.ant-notification-notice-content .clickFlag {
  width: 8% !important;
}

.ant-list-item .Client_clientListItem__1Xc1G:active {
  background-color: aquamarine !important;
}

.landingPageFont {
  text-align: justify;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 600;
}

.ant-list-item-meta-title > a {
  color: rgba(0, 0, 0, 0.45) !important;
}

.landing_page {
  display: flex;
}

.modalStyle .ant-modal-close-x {
  display: none !important;
}

.modalStyle .ant-modal-header {
  padding: 0 !important;
  color: rgba(0, 0, 0, 0.85) !important;
  background: #fff !important;
  border-bottom: 0 !important;
  border-radius: 5px 5px 0 0 !important;
}

.modalStyle .ant-modal-header {
  padding: 0 !important;
  color: rgba(0, 0, 0, 0.85) !important;
  background: #fff !important;
  border-bottom: 0 !important;
  border-radius: 5px 5px 0 0 !important;
}

.navbarMenu .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
  margin: 0px 15px !important;
  margin-top: -1px;
  margin-bottom: 0;
  padding: 0 !important;
}

.draftDocumentOffer .ant-avatar-string {
  line-height: 37px !important;
}

.offer-clone .ant-modal-close {
  top: 15px !important;
  right: 15px !important;
}

.offer-clone p {
  text-align: center;
  text-transform: uppercase;
}

.ant-modal-close-x {
  display: block;
  width: 30px !important;
  height: 0px !important;
  font-size: 16px;
  font-style: normal;
  line-height: 25px !important;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
}

.ant-page-header-heading-title {
  color: #8c8c8c !important;
  font-weight: 600 !important;
}

@media only screen and (max-device-width: 1366px) {
  .signupForm .ant-form-item {
    margin-bottom: 5px;
  }
}

/* ----------- iPad Pro 10.5" ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 820px) and (max-device-width: 1180px) and (-webkit-min-device-pixel-ratio: 2) {
  /* .offer_screenForm {
    height: calc(100vh - 800px);
  } */
  /* .offer_draft_docs {
    height: calc(100vh - 600px);
  } */
  .landing_page {
    display: flex;
    
  }
  .landing_page_date {
    position: unset;
  }
}

/* ----------- iPad 3, 4 and Pro 9.7" ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
  /* .offer_screenForm {
    height: calc(100vh - 700px);
  } */
  /* .offer_draft_docs {
    height: calc(100vh - 550px);
  } */
  .landing_page {
    display: flex;
 
  }
  .landing_page_date {
    position: unset;
  }
}

/* Portrait and Landscape */
@media only screen and (max-device-width: 480px) {
  /* .offer_screenForm {
    height: calc(100vh - 339px);
  } */
  /* .offer_draft_docs {
    height: calc(100vh - 451px);
    margin-bottom: 73px;
  } */
  .landing_page {
    display: flex;
 
  }
  .landing_page_date {
    position: unset;
  }
  .ant-drawer-content-wrapper{
    width: 330px !important;
  }
}
