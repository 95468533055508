.formcreate label {
  font-size: 16px !important;
  font-weight: 600;
}

.createtitle {
  font-size: 26px;
  font-weight: 600;
  padding: 0px 0 0px 0;
  border-bottom: 2px solid #f0f0f0;
  margin-bottom: 11px;
}

/* .formcreate label:before {
  position: absolute;
  right: -14px;
} */

.ant-drawer-content-wrapper {
  font-size: 30px;
}

.offerText {
  color: rgba(0, 0, 0, 0.45) !important;
}

.avtarText{
    color:white !important;
}

.formcreate label.item-required:not(.item-required-mark-optional)::before {
  margin-right: 0;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
  right: -10px;
  position: absolute;
}

.ant-col .ant-form-item-label {
  margin-bottom: 4px !important;
  color: rgba(0, 0, 0, 0.85) !important;
  font-size: 14px !important;
  line-height: 1.5715 !important;
}

.demoloadlisting {
  color: #143b7c;
  border: none !important;
}

.clientListItem {
  cursor: pointer;
}

.clientListItem:hover {
  background-color: #b3c9ee;
}

.listItem:hover{
 background: #74b2ec;
}

/* input {
  height: 40px;
  line-height: 40px;
} */


@media (min-width: 600px) {

  .priceAlignment {
    padding: 0px 0px 0px 10px;
  }
}

.documentContainer {
  display: flex;
}

.stepsContainer {
  height: calc(100vh - 7.8rem);
}

.stepBox {
  display: block;
}

.clientThumbnailContainer {
  height: calc(100vh - 260px);
}

.percentageCircle {
  display: flex;
  justify-content: center;
  /* margin-bottom:60px; */
}

@media only screen and (max-device-width: 480px) {
  .percentageCircle {
    display: block;
  }

  .documentContainer {
    display: block;
  }

  .stepsContainer {
    height: 35vh;
  }

  .stepBox {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    flex-direction: column;
  }

  .clientThumbnailContainer {
    height: calc(100vh - 380px);
  }
}
.heading {
  text-align: center;
  width: 60%;
  margin-top: -15px;
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  font-family: sans-serif;
}
.heading h2 {
  color: grey;
}
.list {
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  margin-top: 10px;
  cursor: pointer;
  text-align: start;
  font-size: 17px;
  font-family: sans-serif;
  display: flex;
  flex-direction: column;
}
.list div {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}
.title {
  width: 165px;
  height: auto;
}

.desc {
  width: 400px;
  height: auto;
}
.listNode {
  display: flex;
  flex-direction: row;
}
.list:hover {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  background: #bbdef3;
}

.box {
  /* display: flex; */
  /* justify-content: space-around; */
  max-height: 145px;
  width: 90%;
  margin: auto;
  align-items: center;
  margin-top: 5px;
  transform: translate (-50%, -40%);
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 8px;
  background-color: white;
  box-sizing: border-box;
  padding: 8px;
  overflow: hidden;
  overflow-y: auto;
}
.addressHeading{
    text-align:center;
    display:flex;
    justify-content:center;
    flex-grow:1;
    color: black;
    font-size: 15px;
    font-family: sans-serif;
    font-weight: 700;
    margin-top: -5px;
    margin-bottom: 5px;
}
.imgBox {
  /* height: 70px; */
}
.imgBox img {
    width: 140px;
    height: 90px;
}
.addressbox {
  height: 100%;
  width: 90%;
  /* margin: auto; */
  line-height: 17px;
  box-sizing: border-box;
  padding: 5px;
}

.addressbox p {
  color: #2976be;
  font-size: 15px;
  font-family: sans-serif;
  font-weight: 500;
  text-align: start;
  padding-left: 5px;
  margin-top: -10px;
}

.addressbox p span{
   font-weight: bold;
  }

.addressbox .ownerNames{
    line-height: 14px;
    overflow: hidden;         
    white-space: nowrap;      
    text-overflow: ellipsis;
    max-width: 200px; 
}

@media only screen and (max-device-width: 385px) {
  .documentContainer {
    display: block;
  }

  .stepsContainer {
    height: 45vh;
  }

  .stepBox {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    flex-direction: column;
  }

  .clientThumbnailContainer {
    height: calc(100vh - 0px);
  }
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.pageButton {
  background-color: rgb(24,144,255);
  color: white;
  border: none;
  padding: 7px 12px;
  cursor: pointer;
  margin: 0 5px;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.disabled {
  background-color: grey;
  cursor: not-allowed;
}
.pageText {
  font-size: 18px;
  margin: 0 10px;
}


.scrollContainer {
  height: 480px;
  overflow-y: auto;
}

@media screen and (max-width:877px) {
    .addressbox p {
        font-size: 12px;
      }
}

@media screen and (max-width:815px) {
    .addressbox p {
        font-size: 10px;
        line-height: 14px;
      }
}

@media screen and (max-width:699px) {
      .addressHeading{
          font-size: 10px;
      }
      .imgBox {
        height: 50px;
        display: flex;
        justify-content: center;
      }
      .imgBox img {
        width: 100px;
        height: 100%;
      }
      .boxContent{
        display: flex;
        flex-direction: column;
      }
      .addressbox{
        margin-top: 10px;
      }

}

@media all and (min-width:768px) and (max-width:991px) {
  .addressHeading{
    font-size: 10px;
  }
  .imgBox {
    height: 50px;
    display: flex;
    justify-content: center;
  }
  .imgBox img {
    width: 100px;
    height: 100%;
  }
  .boxContent{
    display: flex;
    flex-direction: column;
  }
  .addressbox{
    margin-top: 10px;
  }
}

