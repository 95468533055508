.templateNav {
  margin-top: 4px;
}
.templateTitle {
  font-size: 16px;
  font-weight: bold;
  color: grey !important;
  flex-grow: 1;
  max-width: 80%;
}

.templateIndivisualBox {
  height: 100px;
  margin-bottom: 50px;
  display: flex !important;
  align-items: center !important;
}

.tempDescription {
  font-size: 15px;
  height: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: grey ;
  margin-top: 4px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /*  only two lines max */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.templatebutton{
  margin:1rem

}

@media only screen and (max-device-width: 480px) {
  .templateNav {
    margin-top: -20px;
  }
  .templatebutton{
    margin:0.5rem
  
  }
  .templateTitle {
    font-size: 15px;
  }

  .tempDescription {
    font-size: 14px;
  }

  .templateIndivisualBox {
    height: 100px;
    margin-bottom: 10px;
  }
}
