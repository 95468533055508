.container {
    width: 100%;
   background-color: #5969a9;
    color: aliceblue;
    text-align: left;
    padding: 10px;
}

.container .title h3 {
    color: #20398b;
    font-weight: 700;
    font-size: large;
}

ul {
    margin: 0;
    padding: 0 15px;
}

@media all and (min-width:771px) and (max-width: 1199px) {
    .container .title h3 {
        color: #20398b;
        font-weight: 500;
        font-size: medium;
    }

    .content {
        font-size: small;
    }
}

@media all and (min-width:500px) and (max-width: 770px) {
    .container .title h3 {
        color: #20398b;
        font-weight: 500;
        font-size: medium;
    }

    .content {
        font-size: small;
    }
}

@media all and (max-width: 499px) {
    .container .title h3 {
        color: #20398b;
        font-weight: 500;
        font-size: medium;
    }

    .content {
        font-size: small;
    }
}