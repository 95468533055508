.landingPage {
  padding: 0.2rem 1rem 0.9rem 1rem;
  border-radius: 8px;
  background-color: #fff;
  transition: 0.3s;
}

.clientLoader {
  background-color: #0e0e0eb3;
  width: 100%;
  height: calc(100vh - 6rem);
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  z-index: 1000;
  justify-content: center;
  align-items: center;
}
.tabContainer {
  width: 100%;
}

@media only screen and (max-device-width: 480px) {
  .landingPage {
    padding: 0.2rem 0 0.9rem 0;
  }

  .tabPaneTitleday {
    font-size: 17px;
    margin-left: 10px;
  }

  .tabPaneTitleWeek {
    font-size: 17px;
  }
}

@media only screen and (max-device-width: 395px) {
  .tabPaneTitleday {
    font-size: 13px;
  }

  .tabPaneTitleWeek {
    font-size: 13px;
  }

  .tabPaneTitleDate {
    font-size: 100px;
    display: block;
  }
}

@media only screen and (max-device-width: 376px) {
  .tabPaneTitleday {
    font-size: 14px;
    margin-left: 15px;
  }

  .tabPaneTitleWeek {
    font-size: 14px;
  }
}
