.panelCompBox{
  width: 97%;
  margin: auto;
  padding: 0px 0px 5px 0px;
  font-size: 15px;
}

.noteImage {
  width: 60px;
  height: 60px;
}

.clients{
  color: rgb(96, 96, 96);
  font-size: 0.97rem;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  letter-spacing: 0.4px;
  display: flex;
  gap: 5px;
}

.transactionDetail{
  display: grid;
  grid-template-columns: 280px 63% 50px;
  padding-top: 7px;
}

.firstImage{
  width: 200px !important;
  height: 100px !important;
  object-fit: cover;
}

.secondDetail{
  display: grid;
  grid-template-rows: 32px 32px 32px;
  grid-template-columns: 55% 45%;
  color: rgb(96, 96, 96);
  font-size: 1.08rem;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  letter-spacing: 0.4px;
}

.secondDetailList{
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
}

.secondDetailList:hover{
  color: #039aff
}

.propertyInfo{
  cursor: pointer;
  margin: 18%;
  color: rgb(0, 0, 238);
  text-decoration: underline;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  cursor: pointer;
  &:hover{
    color: rgb(85, 26, 139);
    cursor: pointer;
  }
}

.thirdDetail{
  display: flex;
  justify-content: end;
  align-items: end;
}

@media screen and (max-width: 1025px) {
  .firstImage{
    width: 180px !important;
  }

  .transactionDetail{
    grid-template-columns: 260px 60% 40px;
  }

  .noteImage {
    width: 50px;
    height: 50px;
  }
}

@media screen and (max-width: 803px) {

  .transactionDetail{
    grid-template-columns: 230px 60% 40px;
    grid-auto-flow: dense;
  }
  .secondDetail{
    grid-template-rows: 1.8rem 1.8rem 1.8rem;
    grid-template-columns: 55% 45%;
  }

  .firstImage{
    width: 140px !important;
    height: 80px !important;
  }

  .noteImage {
    width: 46px;
    height: 46px;
  }
}

@media screen and (max-width: 710px) {
  .transactionDetail{
    grid-template-columns: 50% 50%;
    grid-auto-flow: dense;
  }

  .transactionDetail > div:nth-child(2) {
    grid-column: 1 / -1;
  }

  .secondDetail{
    grid-template-columns: 55% 45%;
    grid-template-rows: 1fr min-content;
    padding: 5px 0px 0px 0px;
    gap: 4px;
  }

  .firstImage{
    width: 150px !important;
  }
}

@media screen and (max-width: 440px) {
  .transactionDetail{
    grid-template-columns: 70% 30%;
  }

  .noteImage {
    width: 40px;
    height: 40px;
  }

  .secondDetail{
    grid-template-columns: 100%;
    grid-template-rows: 1fr min-content;
  }

  .propertyInfo{
    cursor: pointer;
    margin: 18%;
    font-size: 15px;
    font-weight: bold;
    color: rgb(96, 96, 96);
    font-family: "Roboto", sans-serif;
  }
}
