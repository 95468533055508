.page {
  background-color: white;
  position: relative;
  z-index: 0;
}
.vector {
  position: absolute;
  z-index: 1;
}
.image {
  position: absolute;
  z-index: 2;
}

@media (min-width: 601px) {
  .text {
    position: absolute;
    z-index: 11;
    opacity: inherit;
    white-space: nowrap;
    font-size: medium;
  }
}
@media (max-width: 600px) {
  .text {
    position: absolute;
    z-index: 11;
    opacity: inherit;
    white-space: nowrap;
    font-size: small;
  }
}
.annotation {
  position: absolute;
  z-index: 5;
}
.control {
  position: absolute;
  z-index: 13;
}
.annotation2 {
  position: absolute;
  z-index: 7;
}
.dummyimg {
  vertical-align: top;
  border: none;
}
.signInbutton {
  position: fixed !important;
  bottom: 23%;
  background-color: #fffc00 !important;
  color: #f40000 !important;
  padding: 1px 9px !important;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 14px !important;
  border: none !important;
  z-index: 1;
}
.signInbutton::after {
  content: "";
  width: 0;
  height: 0;
  border-top: 13px solid transparent;
  border-left: 19px solid #fffc00;
  border-bottom: 19px solid transparent;
  position: absolute;
  top: 0;
  bottom: 0;
  right: -19px;
}
.inputbox {
  border-radius: 0;
  border: none;
  padding: 3px;
  padding: 0.375rem 0.75rem;
  font-size: 0.8em;
  font-weight: 400;
  line-height: 1.5;
  color: #0052ff !important;
  background-color: #fff;
  background-clip: padding-box;
  -webkit-appearance: none;
  appearance: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  outline: unset;
  margin-left: 1rem;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid #8d8787;
}
@media (max-width: 850px) {
  #rpanrResize {
    display: none;
  }
  .reSizerWrapper {
    display: grid;
    grid-gap: 29px;
  }
}
@media (min-width: 850px) {
  #rpanrResize {
    display: flex;
    border: 1px #7d7d80 solid;
    width: 100%;
    height: 100%;
    flex-grow: 0;
    flex-shrink: 0;
    background: #7d7d80;
    width: 0.5rem;
    cursor: col-resize;
    margin: 0 0 0 auto;
  }
  .reSizerWrapper {
    display: flex;
    border: 1px black solid;
    width: 100%;
    /* height: 100vh; */
  }
}
.commanDivClass {
  /* display: grid;
    grid-template-rows: 35% 65%; */
}
/* @media (max-width: 600px) {
    .ant-modal {
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      color: rgba(0, 0, 0, 0.85);
      font-size: 14px;
      font-variant: tabular-nums;
      line-height: 1.5715;
      list-style: none;
      -webkit-font-feature-settings: "tnum";
      font-feature-settings: "tnum", "tnum";
      pointer-events: none;
      position: relative;
      top: 100px;
      width: auto;
      max-width: calc(100vw - 32px);
      margin: 0 auto;
      padding-bottom: 24px;
    }
  } */
.checkbox {
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  width: 17px;
  height: 17px;
  margin: 0 7px;
  top: 3px;
  position: relative;
  transition: background-color 0.15s ease-in-out,
    background-position 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  border-radius: 0.25em;
  -webkit-print-color-adjust: exact;
  outline: unset;
  margin-left: 1rem;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid #8d8787;
}
.textareaBox {
  border-radius: 0;
  width: 6.5rem;
  border: none;
  padding: 3px;
  padding: 0.375rem 0.75rem;
  font-size: 0.9em;
  font-weight: 400;
  line-height: 1.5;
  background-color: #fff;
  background-clip: padding-box;
  -webkit-appearance: none;
  appearance: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  outline: unset;
  /* margin-left: 1rem; */
  border-radius: 0;
  border: none;
  color: #0052ff !important;
  border-bottom: 1px solid #8d8787;
}
.dateBox {
  border-radius: 0;
  border: none;
  border-bottom: 1px solid #8d8787;
  padding: 3px;
  font-size: 0.9em;
  background-color: #fff;
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  color: #0052ff !important;
  appearance: none;
  outline: unset;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid #8d8787;
  width: -webkit-fill-available;
  max-width: 262px;
}
.signaturealighnment {
  height: 55px;
}
.signatureDivAlighnment {
  position: absolute;
  padding: 0 !important;
  display: grid;
  grid-template-columns: 1fr 1fr;
  left: 96.8px;
  top: -23.7px;
  width: 283px;
  z-index: 12;
  border: 1px solid #b6dfdc;
  background-color: #b6dfdc;
  color: rgb(117, 117, 117);
  height: 72px;
  border-radius: 7px;
  box-shadow: rgb(188 184 184) 0px 3px 6px;
}
.signatureResureAlighnment {
  background: #95d3ce;
  color: darkblue;
}
.creatingAdhock {
  display: inline-block;
  margin: 0 1rem;
  font-size: 13px;
  left: 230.9px;
  top: 473.2px;
  width: 384.3px;
  height: 17.5px;
  background-color: rgba(210, 230, 250, 0.29);
  border-width: 0px 0px 1px;
  border-top-style: initial;
  border-right-style: initial;
  border-bottom-style: solid;
  border-left-style: initial;
  border-top-color: initial;
  border-right-color: initial;
  border-bottom-color: rgb(141, 135, 135);
  border-left-color: initial;
  border-image: initial;
  box-shadow: none;
  outline: none;
  padding: 0px 8px;
  font-weight: 700;
  color: #0052ff !important;
}
.theadforcreatingAdhock {
  display: inline-block;
  border: 1px solid #3c3a3a;
  padding: 0.1rem 1rem;
  margin: 0 1rem;
}
.ant-table-tbody > tr > td {
  position: relative;
  padding: 8px 8px;
  overflow-wrap: break-word;
}
#pan1 {
  display: flex;
  border: 1px #7d7d80 solid;
  width: 100%;
  height: 100%;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 25%; /* initial status */
  overflow: auto;
}
#pan2 {
  display: flex;
  border: 1px #7d7d80 solid;
  width: 100%;
  height: 100%;
  flex-grow: 0;
  flex-shrink: 1;
  overflow-x: auto;
  justify-content: center;
}

dialog {
  border: none;
  border-radius: 5px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.dialog-container {
  padding: 50px 10px 20px;
  background-color: white;
}
.dialog-container > div > input {
  width: 200px;
  height: 32px;
  font-size: 18px;
}
#remove {
  background-color: #dd5739;
  color: rgb(247, 243, 243);
  border: none;
  border-radius: 5px;
  font-size: 18px;
  margin-top: 10px;
  margin-left: 50px;
  display: inline-block;
}
#remove_1 {
  color: black;
  font-size: 24px;
  margin: 3px 0px 0px;
}
#propChange {
  background-color: #1890ff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  margin-top: 10px;
  margin-left: 50px;
  display: inline-block;
}
#propChange_1 {
  color: black;
  font-size: 24px;
  margin: 3px 0px 0px;
}
#propChange_1:hover {
  border: 2px solid black;
}
#cancel {
  background-color: #f4f8fb;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  margin-top: 10px;
  margin-left: 50px;
  display: inline-block;
}
#cancel:hover {
  border: 2px solid #f4f8fb;
}
#propChange:hover {
  border: 2px solid #1890ff;
}
.dialog-container > .flex-box {
  display: flex;
  justify-content: space-around;
}

.dialog-container > div > .label {
  width: 100px;
  font-size: 18px;
  color: rgb(80, 80, 80);
}
.flex-box {
  display: flex;
  flex-direction: row;
}
.justify-content {
  justify-content: space-between;
}
.drag-input {
  border: none;
  border-bottom: 1px solid black;
  background-color: #f2f8fe;
}
#top-bar {
  position: fixed;
  margin-left: 220px;
  margin-top: -500px;
  padding: 5px;
  width: 76vw;
  color: rgb(27, 25, 25);
  background-color: rgb(230, 228, 228);
  border-radius: 5px;
}
.top-bar-input {
  border: none;
  border-bottom: 1px solid black;
  background-color: transparent;
  color: black;
  width: 60px;
  height: 28px;
  border-right: 1px solid rgb(103, 136, 167);
}
.text-input {
  width: 75vw;
}
.empty {
  width: 15px;
  height: 28px;
}
#bold {
  font-weight: bold;
  color: black;
  background-color: #f4f8fb;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  display: inline-block;
  width: 30px;
}
#italic {
  font-style: italic;
  color: black;
  background-color: #f4f8fb;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  display: inline-block;
  margin-left: 5px;
  width: 30px;
}
#underlined {
  text-decoration: underline;
  color: black;
  background-color: #f4f8fb;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  margin: 0px 5px 0px;
  display: inline-block;
  width: 30px;
}
#word-break_1 {
  border: none;
  background-color: transparent;
  border-bottom: 1px solid grey;
  border-right: 1px solid grey;
  font-size: 16px;
}
.tooltip {
  position: relative;
}
.tooltip:before {
  content: attr(data-text);
  position: absolute;
  top: 50%;
  /* transform:translateY(-50%); */
  left: 1%;
  margin-left: 10px;
  padding: 0px 3px 0px;
  border-radius: 5px;
  background: white;
  color: #1d90fc;
  text-align: center;
  display: none;
}
.tooltip:hover:before {
  display: block;
  transition: display 4s;
  transition-timing-function: ease-in;
}
.flex-box-1 {
  display: flex;
}

.doc-box {
  width: 20vw;
  height: 50vh;
  background-color: white;
  position: absolute;
  margin-top: 10vh;
  margin-right: 10px;
  top: 0;
  right: 0;
  z-index: 3;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
#top-bar-react {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 4;
  background-color: rgb(107, 107, 107);
  width: 100vw;
}
