.resureTitle {
  color: white;
  font-size: 22px;
  font-weight: 800px;
  padding-top: 2px;
}
.resurelogo{
width:90px; 
height:50px;
 padding-bottom: 5px
}
.realterName {
  color: #fff;
  /* text-transform: capitalize; */
  font-size: 22px;
  font-weight: 800px;
}

.bellIcon {
  display: none;
}
.helpIcon{
  display: none;
}
@media only screen and (max-device-width: 480px) {
  .resureTitle {
    display: none;
  }

  .realterName {
    display: none;
    font-size: 18px;
  }
  .resurelogo{
    width:70px; 
    height:40px;
     padding-bottom: 5px;
     margin-right:20px
    }
  .bellIcon {
    display: block;
    color: white;
    padding-bottom: 10px;
    height: 35px;
    margin-right: -35px;
  }
  .helpIcon{
    display: block;
    height: 40px;
    margin:3px 10px 0 5px;
  }
}
.sectionNamee {
  display: "flex";
  max-height: "40px";
  width: "130px";
  color: red;
  background-color: red;
}
