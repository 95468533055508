.navContainer{
  /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; */
  min-height: 50px;
  width: 100%;
  margin: 5px auto;
  /* display: grid;
  grid-template-columns: 10% 80% 10%; */
  overflow: hidden;
  position: sticky;
  padding: 10px 10px 0px 10px;
}
.searchInputBox{
    width: 400px;
}

.centerNavSection{
  display: flex;
  justify-content: center;
  align-items: center;
}

.filterClearBtn {
    border: none;
    background: #3a74b6;
    color: whitesmoke;
    font-weight: bold;
    border-radius: 10px;
    padding: 5px;
    cursor: pointer;
  }

.filterBox{
    margin-left: 10px;
}

.sortBox {
    display: flex;
    background: #3a74b6;
    margin-left: 10px;
  }

  .sortLeftBtn {
    background: #3a74b6;
    color: whitesmoke;
    font-weight: bold;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border: none;
    padding: 5px;
    display: flex;
  }

  .sortRightBtn {
    background: #3a74b6;
    color: whitesmoke;
    font-weight: bold;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border: none;
    padding: 5px;
    display: flex;
  }


  @media screen and (max-width: 1150px) {
    .navContainer{
      grid-template-columns: 15% 70% 15%;
    }
  }

@media screen and (max-width: 593px) {

  .navContainer{
    /* grid-template-columns: 25% 50% 25%; */
  }

  .searchInputBox {
    width: 90%;
    position: relative;
    top: 80px;
  }

  .filterBox{
    position: relative;
    left: -140px;
    z-index: 20;
  }

  .sortBox{
    position: relative;
    top: -23px;
    z-index: 20;
  }
  .switchButtonContainer{
    position: relative;
    top: -45px;
    z-index: 20;
  }

}
