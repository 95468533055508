.landingPage {
  padding: 0.2rem 0.3rem 0.9rem 0.3rem;
  border-radius: "8px";
  background-color: "#fff";
  transition: "0.3s";
  
}

.reminderList::-webkit-scrollbar{
  width: 0px;
  height: 0px;
  display: none;
}

.tabContainer {
  width: 100%;
 

}

.singleTextWraping{
  width: 90%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
          line-clamp: 1; 
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.createtitle {
  font-size: 26px;
  font-weight: 600;
  padding: 0px 0 0px 0;
  border-bottom: 2px solid #f0f0f0;
  margin-bottom: 11px;
}

.tabSupport {
  display: flex;
}

.accordianSubtitle {
  display: none;
}
.mytext{
  font-weight: 500;
  font-size: 20px;
  color: #5c5555;
 
}

@media only screen and (max-device-width: 800px) {
.mytext{
  font-weight: 500;
  font-size: 20px;
  color: #5c5555;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100px;
  cursor: pointer
}
}
@media only screen and (max-device-width: 480px) {
  .landingPage {
    padding: 0.2rem 0 0.9rem 0;
  }

  .tabPaneTitleMyDash {
    font-size: 17px;
    margin-left: 20px;
   
  }

  .tabPaneTitleMetric {
    font-size: 17px;
  }

  .tabPaneTitleSupport {
    font-size: 17px;
  }

  .tabSupport {
    display: block;
  }

  .accordianSubtitle {
    display: flex;
    margin-bottom: 50px;
  }
  .tutorials{
    height: calc(100vh - 250px);
  }
  .contactSupport{
    height: calc(100vh - 250px);
  }
}

@media only screen and (max-device-width: 395px) {
  .tabPaneTitleMyDash {
    font-size: 15px;
    margin-left: 20px;
  }

  .tabPaneTitleMetric {
    font-size: 14px;
  }

  .tabPaneTitleSupport {
    font-size: 14px;
  }
}
@media only screen and (max-device-width: 480px){
  .tutorials{
    height: calc(100vh - 310px);
  }
  .contactSupport{
    height: calc(100vh - 350px);
  }
}
@media only screen and (max-device-width: 376px) {
  .tabPaneTitleMyDash {
    font-size: 14px;
    margin-left: 15px;
  }

  .tabPaneTitleMetric {
    font-size: 14px;
  }

  .tabPaneTitleSupport {
    font-size: 14px;
  
  }
  .changeclient{
    visibility: hidden;
  }
}
@media only screen and (max-device-width: 320px) {
.tabContainer{
  width: 120%;
}
}

@media only screen and (max-device-width: 1199px) {
  .tabPaneContainer{
    height: calc(100vh - 12.5rem);
    width: 100%;
    display: flex;
    flex-direction: row;
    overflow-x: auto;
  }

  .landingPage {
    padding: 0.2rem 0 0.9rem 0;
  }
}
