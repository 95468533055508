.toolsContainer {
  margin-top: 5px;
}

.tabPaneNameBug {
  font-size: 17px;
  color: grey;
}
.tabPaneName {
  font-size: 17px;
  color: grey;
}

.reactquill{
  height: 150px;
  margin-bottom: 100px;
  max-Width:500px;
  width:100%;
}
.docsclausesearch{
    position:absolute;
    top:20;
    left:40%;
    z-index:111;
    padding:10px;
    width:40%;
    display:flex;
    justify-content:space-around
  
}
.buttonfontsize{
  font-size: 16px;
}
.docsClause{
width: 100%;
 height: 100%;
  padding: 0px
}
.clauselist{
  height: calc(100vh - 240px);
  background-color: #fff
}
.emailtemplate{
  width:100%
}
.tabscreenwidth{
  width:100%
}

@media only screen and (max-device-width: 920px) {
  .clauselist{
    height: calc(100vh - 310px);
 }
  .emailtemplate{
    width:130%
  }
  /* .tabscreenwidth{
    width:150%
  } */
  .docsClause{
    width: 200%;
     height: 100%;
      padding: 0px
    }
  .docsclausesearch{
    position:relative;
    /* top:20;
    left:40%;
    z-index:111;
    padding:10px;
    width:40%; */
    display:flex;
    justify-content:space-around
  
}}

@media only screen and (max-device-width: 480px) {
  .tabscreenwidth{
    width:200%
  }
  .buttonfontsize{
    font-size: 10px;
  }
  .emailtemplate{
    width:180%
  }
  .docsclausesearch{
    position:relative;
    /* top:20;
    left:40%;
    z-index:111;
    padding:10px;
    width:40%; */
    display:flex;
    justify-content:space-around
  
}
  .toolsContainer {
    margin-top: -18px;
  }
  .tabPaneName {
    font-size: 15px;
    color: grey;
  }
  .tabPaneNameBug {
    font-size: 15px;
    color: grey;
    margin-left: 24px;
  }

  .reactquill{
    height: 150px;
     margin-bottom: 100px;
   max-Width:350px;
   width:100%;

  }
}
@media only screen and (max-device-width: 400px) {
  .toolsContainer {
    margin-top: -18px;
  }
  .tabPaneName {
    font-size: 13px;
    color: grey;
  }
  .tabPaneNameBug {
    font-size: 13px;
    color: grey;
    margin-left: 15px;
  }
  .reactquill{
    height: 150px;
    margin-bottom: 100px;
    max-width:300px;
   width:100%;
  
  }
}

@media only screen and (max-device-width: 850px) {
    .toolsContainer {
      margin-top: -28px;
    }
  }
