.roleContainer{
 display: flex;
 align-items: center;
 gap: 1rem;
}
.roleContainer .roleIcon{
    display: "flex";
    align-items: "center" 
}
.roleContainer .mainIcon{
    font-size: 1.5rem;
}

