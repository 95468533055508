.modal_main {
  display: flex;
  flex-direction: column;
  margin-top: 2%;
  align-items: center;
}

.modal_row {
  /* display: flex;
  width: 50%;
  margin: auto; */
  display: flex;
  align-items: center;
  justify-content: flex-start;
  align-content: center;
  margin-bottom: 15px;
}

/* .modal_row :hover {
} */

.icon_div {
  margin-left: 14%;
}
.icon_div_draft {
  margin-left: 4%;
}

.content_div {
  width: 190px;
  cursor: pointer;
}

.content_div > p {
  padding-left: 10px;
  margin-bottom: 0px;
  font-size: 22px !important;
  font-weight: bold;
  width: 300px;
  border:"1px solid";
  display: flex;
  flex-direction:"row";
}

.modalContent {
  color: rgb(8, 81, 145);
  font-size: 18px;
}
.modalContenbuttont {
  color: rgba(0, 0, 0, 0.45) !important;
  font-size: 22px !important;
}
.modalContentButton {
  color: rgba(0, 0, 0, 0.45) !important;
  font-size: 22px !important;
  cursor: pointer;
  color: rgb(8, 81, 145) !important;
}

.modalContentButtonDisable {
  /* background-color: rgb(212, 210, 210) !important; */
  color: rgba(63, 63, 63, 0.45) !important;
  font-size: 22px !important;
  cursor: not-allowed;
}

.left_sidebar_property {
  font-size: 20px;
  color: rgba(0, 0, 0, 0.45);
  font-weight: bold;
}
.property-item:hover {
  background-color: rgb(167, 204, 238);
  cursor: pointer;
  z-index: 1;
  border-radius: 5px;
}

.modalContent > div:nth-child(2){
    margin-left: 10px;
}
.modalContentButton > div:nth-child(2){
    margin-left: 10px;
}
.headingFlexBox{
    display: flex;
    width: 300px;
}

.headingFlexBox > div:nth-child(2){
    margin-left: 10px;
}

.headingFlexBox2{
    display: flex;
    width: 300px;
}

.headingFlexBox2 > div:nth-child(2){
    margin-left: 10px;
}

.headingFlexBox3{
    display: flex;
    width: 300px;
}

.headingFlexBox3 > div:nth-child(2){
    margin-left: 10px;
}
@media (max-width: 1305px) {
    .content_div > p {
        display: flex;
        flex-direction: column;

      }

      .modalContent > div:nth-child(2){
        margin-left: 0px;
        border:"1px solid green !important"
    }

    .modalContentButton > div:nth-child(2){
        margin-left: 0px;
    }
    .headingFlexBox{
        display: flex;
        flex-direction: column;
        margin-left: -100px;
        line-height: 25px;
        margin-top: 20px;
        height:"1px solid red" !important

    }

    .headingFlexBox > div:nth-child(2){
        margin-left: 0px;
    }
    .headingFlexBox2{
        display: flex;
        flex-direction: column;
        line-height: 25px;
    }

    .headingFlexBox2 > div:nth-child(2){
        margin-left: 0px;
    }

    .headingFlexBox3{
        display: flex;
        flex-direction: column;
        line-height: 25px;
        margin-left: -90px;
    }

    .headingFlexBox3 > div:nth-child(2){
        margin-left: 0px;
    }


  }
