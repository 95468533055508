#mainLayout {
  height: calc(100vh - 110px);
  position: sticky;
  top: 95;
  width: 100%;
  overflow-y: hidden;
}

#mainLayoutClient {
  height: calc(100vh- 10px);
  position: sticky;
  top: 95;
  width: 100%;
  overflow-y: hidden;
}
/* .table-row td {
  vertical-align: middle ;
} */
.commonModalTitle{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  font-weight: 700;
  color: #2697FF;
  text-transform: capitalize;
}

@media only screen and (max-device-width: 1200px) {
  /* #mainLayout {
    overflow-y: auto;
  } */
  #mainLayoutClient {
    overflow-y: auto;
  }
}

.border-bottom {
  border-bottom: 0px !important;
}
/* for making the :disabled input fontcolor light */
input[type="text"][disabled] {
  color: black;
}
/* custom stylinig for phoneInput placeholder */
.phoneInput::placeholder {
  color: rgba(0, 0, 0, 0.3);
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5715;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.ant-upload.ant-upload-select-picture-card {
  width: 170px !important;
  height: 170px !important;
}
/* Below css will only apply of Mozila browser */
@-moz-document url-prefix() {
  .mozScrollbar {
    /* -moz-appearance: */
    scrollbar-color: #0a4c95 #c2d2e4;
    /* scrollbar-width:  15px !important; */
    scrollbar-width: thin;
    /* scrollbar-width:  none; */
    scrollbar-track-color: white;
    scrollbar-shadow-color: #0a4c95;
    -moz-scrollbar-track-color: hsla(209, 41%, 70%, 1);
    scrollbar-arrow-color: black;
    -moz-scrollbar-arrow-color: black;
  }
  .mozScrollbar:-webkit-scrollbar-thumb {
    background: hsla(208, 90%, 30%, 1);
    border-radius: 10px;
  }
  .mozScrollbar:-webkit-scrollbar-thumb {
    border-radius: 6px;
  }
  .mozFontSize {
    font-size: 1.2rem !important;
  }
  /* .ant-tabs-nav .ant-tabs-tab-active {
    color: red;
 } */
  .ant-tabs-tab .ant-tabs-tab-active {
    font-weight: 800 !important;
  }
}
 /* custom styling for ant design pricing switch btn for the subscription page */
#pricingPageContainer .ant-switch-checked .ant-switch-handle {
  left: calc(100% - 47px);
}
#pricingPageContainer .ant-switch-handle:before {
  border-radius: 50px;
  height: 35px;
  width: 45px;
}
#pricingPageContainer .ant-switch-checked .ant-switch-inner {
  margin: 0 50px 0 7px;
}
#pricingPageContainer .ant-switch-inner {
  font-size: 20px !important;
}

@media only screen and (max-device-width: 480px) {
  .mozFontSize {
    font-size: 0.9rem !important;
  }
}
