.personheading{
    font-weight: bold;
    justify-content: space-between;
    display: flex;
    font-size: 20px;
    width:100%;
    color: #1890ff;
    margin-top:-10px


}
.corpheading{
    font-weight: bold;
    justify-content: space-between;
    display: flex;
    font-size: 20px;
    width:100%;
    color: #1890ff;
    margin-top:5px


}
.offerImportpersonheading{
  font-size: 18px;
   width:100%;
   margin-top:-10px;
   padding-right: 10px;
}
.offerImportcorpheading{
    font-weight: bold;
    justify-content: space-between;
    display: flex;
    font-size: 20px;
    width:100%;
    color: #1890ff;
    margin-top:-10px;
   padding-right: 10px;


}
.contentDiv {
   padding: 10px;
   min-height: 480px;
   max-height: 50vh;
    overflow-y: auto;
    overflow-x: hidden;
}


.selectedRowsOuterDivParent {
    width: 100%;
    
}
.selectedRowsOuterDiv {
    /* padding: 10px; */
    margin-left: 10px;
    margin-bottom: -20px;
     /* Set maximum height */
}
@media screen and  (max-width: 480px) {
    .contentDiv {
        padding: 10px;
        min-height: 300px;
        max-height: 30vh;
         overflow-y: auto;
         overflow-x: hidden;
     }
}