.sectionName {
  display: flex;
  max-height: 40px;
  width: 130px;
}

.sectionNameSelected{
  display: flex;
  max-height: 50px;
  width: 130px;
  background-color: #97c8f6;
}

.fontTabMain{
  font-size: 1.5rem;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
}

.subDivMain{
  display: flex;
  justify-content: center; 
  align-items: center;
  height: 100%;
}

.tabWidth{
  padding: 0px 45px !important
}

.tabWidth:hover{
  border-bottom: 2px solid #1890ff;
}


.mainDiv{
  display: flex; 
  justify-content: center; 
  gap: 10px; 
  align-items: center;
}

.ant-menu-item:focus {
  background-color: transparent;
}

.mainSec li::after {
  border-bottom: none !important;
}

.tabNameSelected {
  color: #123a79;
  font-size: 24px;
}

.tabNameNotSelected {
  color: #8c8c8c;
  font-size: 20px;
}

.video{
  width: 100%;
  height: 600px;
}

.mainSec{
   display: flex;
   justify-content: center;
   width: 100%;
   height: 49px;
   border-bottom: none !important;
}

@media screen and (max-width: 1400px) {
  .tabWidth{
    padding: 0px  40px !important
  }
}

@media screen and (max-width: 1350px) {
  .tabWidth{
    padding: 0px 35px !important;
  }
  
}

@media screen and (max-width: 1325px) {
  .tabWidth{
    padding: 0px 33px !important;
  }
}

@media screen and (max-width: 1300px) {
  .tabWidth{
    padding: 0px 38px !important;
  }
}

@media screen and (max-width: 1100px) {
  .tabWidth{
    padding: 0px 35px !important;
  }
}


@media screen and (max-width: 750px) {
  .tabWidth{
    padding: 0px 3.5% !important;
  }
}

@media screen and (max-width: 670px) {
  .tabWidth{
    padding: 0px 3% !important;
  }
}

@media only screen and (max-width: 560px) {
  .tabWidth{
    padding: 0px 5% !important;
  }

}

@media only screen and (max-device-width: 380px) {
  .sectionName {
    width: 109px;
  }

  .tabNameSelected {
    font-size: 20px;
  }

  .tabNameNotSelected {
    font-size: 16px;
  }
}

@media all and (min-width:480px) and (max-width:790px){
  .video {
    height: 300px;
  }
}

@media all and (max-width: 480px) {
  .video {
    height: 200px;
  }

  .mainSec{
    justify-content: space-between;
  }

  .tabWidth{
    width: 200px;
  }
}



