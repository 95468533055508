.transactionContainer {
    height: calc(93vh - 8rem);
    overflow-y: auto;
    width: 1100px;
    margin: auto;
    padding-right: 15px;
  }

.homeTransactions{
  height: calc(85vh - 7.5rem);
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  width: 98%;
  margin: auto;
}

.homeTransactions::-webkit-scrollbar{
  width: 0px;
  height: 0px;
  display: none;
}

.delegateContainer {
  height: calc(93vh - 11rem);
  overflow-y: auto;
  width: 1100px;
  margin: auto;
  padding-right: 15px;
}

.singleTextWraping{
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
          line-clamp: 1; 
  -webkit-box-orient: vertical;
}

.doublleTextWraping{
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
          line-clamp: 2; 
  -webkit-box-orient: vertical;
}

  .profileBox {
    width: 100%;
    display: flex;
    align-items: start;
    text-transform: none;
  }

  .homeProfileBox{
    width: 100%;
    display: grid;
    grid-template-columns: 70% 30%;
  }

  .homeProfileBox :first-child {
    grid-column: 1 / -1;
  }

  .profileImgBox {
    display: flex;
    width: 65%;
    padding-right: 15px;
  }

  .homeProfileImgBox{
    display: flex;
    width: 100%;
    padding-right: 0;
  }
  
  .profileImg:hover{
    border: 4px solid #039aff !important;
  }

  .profileImg2:hover{
    background: #039aff !important;
  }

  .allHighlightDiv:hover{
    background-color: #E7F5E4 !important;
  }

  .brokerDetails {
    margin-left: 10px;
    width: 100%;
  }

  .homeBrokerDetails{
    margin-left: 10px;
    width: 100%;
  }

  .addSellers{
    color: #3D59AB ; 
    font-weight: 700 ; 
    padding: 0px ; 
    text-decoration: underline;
    text-decoration-thickness: 1.5px;
  }

  .hoverClients:hover{
    stroke: #039aff !important;
    color: #039aff !important;
  }

  .brokerDetails > div:nth-child(1) {
    color: rgb(96, 96, 96);
    font-size: 1.2rem;
    font-weight: 600;
    font-family: "Roboto", sans-serif;
    letter-spacing: 0.4px;
  }

  .brokerDetails > div:nth-child(2){
    color: rgb(143, 143, 143);
    font-size: 0.96rem;
    font-weight: 600;
    font-family: "Roboto", sans-serif;
    letter-spacing: 0.4px;
  }

  .brokerDetails > div:nth-child(3){
    color: rgb(143, 143, 143);
    font-size: 0.96rem;
    font-weight: 600;
    font-family: "Roboto", sans-serif;
    letter-spacing: 0.4px;
  }

  .homeBrokerDetails > div:nth-child(1) {
    color: rgb(96, 96, 96);
    font-size: 0.9rem;
    font-weight: 600;
    font-family: "Roboto", sans-serif;
    letter-spacing: 0.4px;
  }

  .homeBrokerDetails > div:nth-child(2) {
    color: rgb(143, 143, 143);
    font-size: 0.8rem;
    font-weight: 600;
    font-family: "Roboto", sans-serif;
    letter-spacing: 0.4px;
  }

  .homeBrokerDetails > div:nth-child(3) {
    color: rgb(143, 143, 143);
    font-size: 0.8rem;
    font-weight: 600;
    font-family: "Roboto", sans-serif;
    letter-spacing: 0.4px;
  }
  
  /* .brokerDetails > div:nth-child(3) {
    letter-spacing: 0.4px;
    font-size: 0.96rem;
    font-weight: 600;
    color: rgb(143, 143, 143);
    font-family: "Roboto", sans-serif;
  } */

  .sellingCard{
    border: 2px solid #3d59ab;
    padding: 0;
    border-radius: 3px;
    width: 100px;
    text-align: center;
    color: #3d59ab;
    font-size: 1.05rem;
    font-weight: 600;
    font-family: "Roboto", sans-serif;
    letter-spacing: 0.4px;
    display: flex;
    justify-content: center;
  }

  .homeSellingCard{
    border: 2px solid #3d59ab;
    padding: 0;
    border-radius: 3px;
    width: 100px;
    text-align: center;
    color: #3d59ab;
    font-size: 0.8rem;
    font-weight: 600;
    font-family: "Roboto", sans-serif;
    letter-spacing: 0.4px;
    display: flex;
    justify-content: center;
  }

  .buyingCard{
    border: 2px solid #3d59ab;
    padding: 0;
    border-radius: 3px;
    width: 100px;
    text-align: center;
    background: #3d59ab;
    color: white;
    font-size: 1.05rem;
    font-weight: 500;
    font-family: "Roboto", sans-serif;
    letter-spacing: 0.5px;
    display: flex;
    justify-content: center;
  }

  .homeBuyingCard {
    border: 2px solid #3d59ab;
    padding: 0;
    border-radius: 3px;
    width: 100px;
    text-align: center;
    background: #3d59ab;
    color: white;
    font-size: 0.8rem;
    font-weight: 500;
    font-family: "Roboto", sans-serif;
    letter-spacing: 0.5px;
    display: flex;
    justify-content: center;
  }

  .offerNumberCard{
    width: 100px;
    border: 2px solid #3d59ab;
    color: #3d59ab;
    text-align: center;
    border-radius: 5px;
    margin-top: 5px;
    line-height: 25px;
    font-size: 1.05rem;
    font-weight: 600;
    font-family: "Roboto", sans-serif;
    letter-spacing: 0.4px;
    cursor: pointer;
  }

  .offerNumberCard > div:nth-child(1){
    font-size: 1.4rem;
  }

  .homeOfferNumberCard{
    width: 100px;
    border: 2px solid #3d59ab;
    color: #3d59ab;
    text-align: center;
    border-radius: 5px;
    margin-top: 1px;
    line-height: 20px;
    font-size: 0.8rem;
    font-weight: 600;
    font-family: "Roboto", sans-serif;
    letter-spacing: 0.2px;
    cursor: pointer;
  }

  .homeOfferNumberCard > div:nth-child(1){
    font-size: 1.2rem;
  }

  .linkMls:hover{
    color:#039aff
  }

  .statusBox {
    width: 250px;
  }

  .statusBox > div:nth-child(1){
    display: flex;
    flex-direction: column;
    align-items: end;
    -webkit-align-items: flex-end;
    min-height: 82px;
    gap: 2px;
  }

  .homeStatusBox{
    width: 250px;
  }

  .homeStatusBox > div:nth-child(1){
    display: flex;
    flex-direction: column;
    align-items: start;
    -webkit-align-items: flex-start;
    min-height: 70px;
    gap: 2px;
    padding: 7px 0px 0px 0px;
    
  }  

  .statusBox * div {
    font-size: 0.96rem;
    color: rgb(96, 96, 96);
    font-weight: 600;
    font-family: "Roboto", sans-serif;
    letter-spacing: 0.4px;
  }

  .homeStatusBox * div {
    font-size: 0.96rem;
    color: rgb(96, 96, 96);
    font-weight: 600;
    font-family: "Roboto", sans-serif;
    letter-spacing: 0.4px;
  }

  .offerDetailBox {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: center;
    width: 130px;
  }

  .offerDetailBox div {
    align-self: flex-end;
  }

  .homeOfferDetailBox {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    padding: 7px 0px 0px 0px;
  }

  .homeofferDetailBox div {
    align-self: flex-end;
  }

  .panelBox{
    padding: 15px !important;
  }

  .panelBox > div:nth-child(1) {
    padding: 0px !important;
  }

  /* .panelBox:hover {
    background: rgb(234, 255, 255) !important;
  } */

  @media screen and (max-width: 1310px) {
    .transactionContainer {
      width: 90%;
    }

    .homeTransactions{
      width: 98%;
      height: calc(85vh - 7rem);
    }

    .delegateContainer {
      width: 98%;
    }

    html{
      font-size: 16px;
    }
  }

  @media screen and (max-width: 1150px) {
    .transactionContainer {
      width: 90%;
    }

    .homeTransactions{
      width: 98%;
    }

    .profileBox {
      width: 100%;
      display: flex;
      text-transform: none;
      overflow: auto;
    }

    .homeProfileBox{
      width: 100%;
      display: grid;
      grid-template-columns: 70% 30%;
    }
  
    .homeProfileBox :first-child {
      grid-column: 1 / -1;
    }

    html{
      font-size: 16px;
    }
  }

  @media screen and (max-width: 1025px) {

    .transactionContainer {
      width: 98%;
      padding-right: 0px; 
      -ms-overflow-style: none;
      scrollbar-width: none;
    }

    .homeTransactions{
      width: 98%;
      padding-right: 0px; 
      -ms-overflow-style: none;
      scrollbar-width: none;
    }

    .transactionContainer::-webkit-scrollbar { 
        display: none;  /* Safari and Chrome */
    }

    .delegateContainer {
      width: 98%;
      padding-right: 0px; 
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
    .delegateContainer::-webkit-scrollbar { 
      display: none;  /* Safari and Chrome */
    }
  
    .offerNumberCard{
      line-height: 22px;
    }

    html{
      font-size: 15.5px;
    }
  }

  @media screen and (max-width: 975px) {

    html{
      font-size: 14.5px;
    }
  }

  @media screen and (max-width: 945px) {
    .profileImgBox {
      width: 60%;
    }

    .statusBox {
      width: 230px;
    }

    .homeStatusBox {
      width: 230px;
    }


    .brokerDetails > div:nth-child(1) {
      font-size: 1.17rem;
    }

    .homeBrokerDetails > div:nth-child(1) {
      font-size: 0.96rem;
    }

    .transactionContainer {
      width: 98%;
    }

    .homeTransactions{
      width: 98%;
      margin-bottom: 10px;
    }
  }



  @media screen and (max-width: 803px) {
    .profileBox {
      width: 100%;
      display: grid;
      grid-template-columns: 70% 30%;
    }

    .profileBox :first-child {
      grid-column: 1 / -1;
    }

    .profileImgBox {
      width: 100%;
      padding-right: 0px;
    }

    .statusBox {
      width: 100%;
    }

    .homeStatusBox {
      width: 95%;
    }

    .statusBox > div:nth-child(1){
      align-items: start;
      padding: 7px 0px 0px 0px;
      gap: 4px;
    }

    .homeStatusBox > div:nth-child(1){
      align-items: start;
      padding: 7px 0px 0px 0px;
      gap: 4px;
    }

    .offerDetailBox {
      width: 100%;
      padding: 7px 0px 0px 0px;
    }

    html{
      font-size: 14.3px;
    }
  }

  @media screen and (max-width: 620px) {

  }

  @media screen and (max-width: 593px) {
    html{
      font-size: 13.5px;
    }
  }

  @media screen and (max-width: 380px) {
    .homeTransactions{
      width: 98%;
      margin-bottom: 10px;
      height: calc(85vh - 8rem);
    }
  }

  @media screen and (min-width:375px) and (max-width: 480px) {
    .homeTransactions{
      width: 98%;
      margin-bottom: 10px;
      height: calc(85vh - 12rem);
    }
  }


  @media screen and (min-width:481px) and (max-width: 599px) {
    .homeTransactions{
      width: 98%;
      margin-bottom: 10px;
      height: calc(85vh - 13rem);
    }
  }

  @media screen and (min-width:600px) and (max-width: 900px) {
    .homeTransactions{
      width: 98%;
      margin-bottom: 10px;
      height: calc(85vh - 12rem);
    }
  }

  @media screen and (min-width:901px) and (max-width: 1089px) {
    .homeTransactions{
      width: 98%;
      margin-bottom: 10px;
      height: calc(85vh - 12rem);
    }
  }

  @media screen and (min-width:1500px) {
    .homeTransactions{
      width: 98%;
      margin-bottom: 10px;
      height: calc(85vh - 6rem)
    }
  }

  @media screen and (min-width:2000px) {
    .homeTransactions{
      width: 98%;
      margin-bottom: 10px;
      height: calc(85vh - 0.1rem)
    }
  }
