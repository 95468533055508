.bg {
  background-color: aqua;
  /* padding */
}

.mainDiv {
  display: flex !important;
  justify-content: space-around !important;
}

.sellerMainDiv {
  background-color: blue;
  color: black;
  width: 100px;
  height: 90px;
  border-radius: 48%;
}

.docsAndVendorDivMain{
  display: flex; 
  flex-direction: column; 
  justify-content: center;
  align-items: center; 
  max-width: 30px;
  margin: 0px 20px
}

.docsAndVendorBDivMain{
  display: flex; 
  flex-direction: column; 
  justify-content: center;
  align-items: center; 
  max-width: 30px;
  margin: 0px 20px
}

.docsAndVendorDiv{
  display: flex; 
  flex-direction: column; 
  justify-content: center; 
  gap: 8px;  
  padding: 10px;
  font-weight: 700; 
  font-family: sans-serif;
  font-size: 1rem;
  cursor: pointer;
}

.sellerHeading {
  display: flex;
  justify-content: center;
  height: 90px;
  text-align: center;
  font-size: 22px;
  font-weight: bolder;
  align-items: center;
}

.sellerHorizontalLine {
  height: 290px;
  width: 10px;
  border-left: 2px solid blue;
  margin-left: 5%;
}

.sellerVerticalLine {
  display: flex;
  border-bottom: 3px solid blue;
  width: 100px;
  width: 27%;
  /* position: absolute;
    top: 7%;
    left: 0px; */
}

.sellerContractDate {
  /* position: absolute;
    top: 2px;
    left: 10px; */
  font-weight: 700;
  display: flex;
  flex-direction: column;
  margin: 10px;
}

.sellerStatusIcon {
  width: 30px;
  height: 30px;
  background-color: black;
  /* position: absolute;
    top: 7px;
    left: 207px; */
  border-radius: 3px;
  /* marginTop:"20px" */
}

.sellerStatusGreen {
  width: 25px;
  height: 25px;
  background-color: green;
  position: absolute;
  top: 9px;
  left: 209px;
  border-radius: 50px;
}

.contractNameDiv {
  /* width: 25px;
    height: 25px; */
  /* background-color: green; */
  position: absolute;
  bottom: -25px;
  left: 130px;
  /* border-radius: 50px */
}

.contractNameDiv2 {
  display: none;
}

.contractNameDiv button {
  width: 200px;
  height: 50px;
  background-color: white;
  border: 2px solid blue;
  border-radius: 10px;
  font-weight: 700;
}

.buyerMainDiv {
  background-color: #fad818;
  color: black;
  width: 100px;
  height: 90px;
  border-radius: 48%;
}

.buyerHeading {
  display: flex;
  justify-content: center;
  height: 90px;
  text-align: center;
  font-size: 22px;
  font-weight: bolder;
  align-items: center;
}

.buyerhorizonatlLine {
  /* background-color: #FAD818; */
  height: 290px;
  width: 10px;
  border-right: 2px solid #fad818;
  margin-right: 5%;
}

.buyerVerticalLine {
  display: flex;
  border-bottom: 3px solid #fad818;
  width: 100px;
  position: relative;
  top: 90px;
  right: 90px;
}

.sellerContractDate {
  position: absolute;
  top: 2px;
  left: 10px;
  font-weight: 700;
}

.buyerStatusIcon {
  width: 30px;
  height: 30px;
  background-color: black;
  position: absolute;
  bottom: -16px;
  right: 100px;
  border-radius: 3px;
  /* marginTop:"20px" */
}

.buyerStatusYellow {
  width: 25px;
  height: 25px;
  background-color: #fad818;
  position: absolute;
  bottom: -13px;
  right: 102px;
  border-radius: 50px;
}

.buyerContractNameDiv {
  /* width: 25px;
    height: 25px; */
  /* background-color: green; */
  position: absolute;
  bottom: -25px;
  right: 130px;
  /* border-radius: 50px */
}

.buyerContractNameDiv button {
  width: 200px;
  height: 45px;
  background-color: white;
  border: 2px solid blue;
  border-radius: 10px;
  font-weight: 700;
}

/* New Design Css  */

.contentMainDiv {
  background-color: aqua;
  width: 40%;
  display: flex;
  justify-content: center;
  margin: 1%;
}

.contentMainDivBuyer {
  background-color: blueviolet;
  width: 40%;
  display: flex;
  justify-content: center;
}

.ant-menu > .ant-menu-item-active {
  background-color: blue;
}

@media only screen and (max-width: 1024px) {
  .docsAndVendorDivMain{
    align-items: start; 
    max-width: 30px;
    margin: 0px 15px 0px 0px
  }

  .docsAndVendorBDivMain{
    align-items: end; 
    max-width: 30px;
    margin: 0px 0px 0px 15px
  }
}