.filterContainer{
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.searchInputBox{
    margin-bottom: 10px;
    width: 90%;
}

.filterbtns {
    background: #275291 !important;
    color: white !important;
    border-radius: 2px !important;
    text-align: center !important;
    margin-left: 10px !important;
    height: 30px !important;
    display: flex !important;
    justify-content: center !important;;
    align-items: center !important;
    font-weight: bold !important;
    width: 120px !important;
  }
.filterComp{
     height: 250px;
     width: 300px;
     display:flex;
}

.btnsBox{
        height: 45%;
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-left: 7px;
}

  @media screen and (max-width: 355px) {
    .filterComp{
     height: 450px;
     width: 300px;
     display:flex;
     flex-direction: column;
     justify-content: space-around;
     align-items: center;
 }
 .searchInputBox{
    margin-bottom: 10px;
    width: 80%;
}
.btnsBox{
    margin-top: 20px;
}
  }
