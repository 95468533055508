.file_icon_wrapper {
    position: relative;
    display: inline-block;
    color: whitesmoke;
  }
  
  .file_icon {
    font-size:1.5rem;
  }
  
  .plus_icon {
    position: absolute;
    top: -9px; 
    right: -11px;
    font-size: 1.125rem; 
    color: whitesmoke; 
  }

  .optionText{
    font-size: 1.5rem;
    font-weight: bold;
  }

  @media only screen and (min-width:1200px) and (max-width:1288px) {
    .optionText{
      font-size: 20px;
      font-weight: bold;
    }
  }

  @media only screen and (min-width:375px) and (max-width:400px) {
    .optionText{
      font-size: 16px;
      font-weight: bold;
    }
  }