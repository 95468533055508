.spin {
  display: flex;
  justify-content: center;
  padding: 30px 50px;
  border-radius: 4px;
  height: 100%;
  align-items: center;
}

.spinLessPadding {
  display: flex;
  justify-content: center;
  padding: 15px 20px 0px 20px;
  border-radius: 4px;
  height: 100%;
  align-items: center;
}
