body {
  background-color: white;
}
.flexbox {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 768px;
  height: auto;
  overflow: hidden;
  margin: 0 auto;
  padding: 15px;
}
.flexbox .board {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 300px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  padding: 15px;
}

.flexbox .board .card {
  /* padding: 15px 25px; */
  /* background-color: white; */
  cursor: pointer;
  margin-bottom: 15px;
}
.flexbox .board .card input {
  /* padding: 15px 25px; */
  /* background-color: white; */
  cursor: pointer;
  width: 100%;
}
.flex {
  display: flex;
  justify-content: space-around;
  font-size: 45px;
}
.tools-text {
  text-align: center;
  font-size: 20px;
  margin-bottom: 4px;
}
.toolbar {
  margin-top: 5%;
  padding: 5px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  border-radius: 5px;
  height: 40vh;
  width: 140px;
  position: absolute;
  top: 25%;
  left: 10;
  background-color: #e6e4e4;
}
.tool {
  border-radius: 5px;
  border-bottom: 1px solid black;
  background-color: white;
  padding: 0px 8px 0px;
  color: black;
  margin-top: 10px;
  margin-left: 2px;
  font-size: smaller;
}
.tool:hover {
  border-radius: 5px;
  background-color: #188df5;
  color: white;
  transition: background-color 2s;
  transition-timing-function: ease-in;
  transition-delay: -1s;
}
.page-view {
  position: absolute;
  top: 15vh;
  left: 13vw;
  width: 175px;
  height: 30vw;
  background-color: #188df5;
}

.doc-input {
  background-color: rgba(210, 230, 250, 0.29);
  border: none;
  box-shadow: none;
  outline: none;
  padding: 0px 8px;
  font-weight: 700;
  border-radius: 5px;
}
.signatureBox {
  background-color: #00ffff;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 280px;
  height: 72px;
  margin-top: -60px;
  padding-top: 20px;
  border-radius: 10px;
  font-weight: 500;
  cursor: pointer;
  z-index: 5;
}

.grids-template-responsive {
  width: 100vw;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 1fr);
  padding-left: 5px;
}

@media screen and (max-width: 990px) {
  .grids-template-responsive {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, 1fr);
    padding-left: 5px;
  }
}
@media screen and (max-width: 675px) {
  .grids-template-responsive {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto;
    padding-left: 5px;
  }
}
