.readyMadeNewsletterImg {
    width: 100%;
    padding: 10px;
    overflow: auto;
    text-align: center;
}

.readyMadeNewsletterImg img {
    width: 100%;
}

.container {
    width: 100%;
    margin: 10px;
}

.title {
    text-align: center;
    width: 80%;
    margin: auto;
    margin-top: 15px;
}

.title>.h1 {
    font-size: 55px;
    border-top: 3px solid;
    border-bottom: 3px solid;
    white-space: nowrap;
    font-weight: 700;
}

.col {
    display: flex;
    width: 100%;
    gap: 10px;
    margin-bottom: 10px;
}

.Logo {
    text-align: center;
    width: 100%;
}

.Logo img {
    width: 200px;
}

.date {
    width: 100%;
    background-color: #085191;
    color: aliceblue;
    display: flex;
    justify-content: center;
    align-items: center;
}

.date .dateVal {
    color: aliceblue;
    font-weight: bold;
    font-size: 30px;
}

.subContainer {
    display: flex;
    gap: 10px;
}

.col1 {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}

.boxPoster {
    width: 100%;
}

.boxPoster img {
    width: 100%;
}

.theFutureTitle {
    border-bottom: 3px solid;
    width: 100%;
    font-size: 30px;
}

.playstoreImg {
    width: 30px;
    height: 30px;
}
.newsletter{
    height:1100px
}
@media all and (min-width:771px) and (max-width: 1199px) {
    .newsletter{
        height:800px;
        overflow-y: auto;
    }

    .title {
        width: 100%;
        margin-top: 10px;
    }

    .title>.h1 {
        font-size: 33px;
        border-top: 2px solid;
        border-bottom: 2px solid;
        font-weight: 500;
    }

    .Logo img {
        width: 100px;
    }

    .date {
        width: 100%;
    }

    .date .dateVal {
        font-weight: bold;
        font-size: 20px;
    }

    .theFutureTitle {
        border-bottom: 2px solid;
        font-size: 23px;

    }

    .playstoreImg {
        width: 25px;
        height: 25px;
    }

    .playstoreLink {
        font-size: 8px;
    }
}

@media all and (min-width:500px) and (max-width: 770px) {
    
    .newsletter{
        height:600px;
        overflow-y: auto;
    }
    .title {
        width: 100%;
        margin-top: 10px;
    }

    .title>.h1 {
        font-size: 33px;
        border-top: 2px solid;
        border-bottom: 2px solid;
        font-weight: 500;
    }

    .Logo img {
        width: 100px;
    }

    .date {
        width: 100%;
    }

    .date .dateVal {
        font-weight: bold;
        font-size: 20px;
    }

    .theFutureTitle {
        border-bottom: 2px solid;
        font-size: 23px;

    }

    .playstoreImg {
        width: 25px;
        height: 25px;
    }

    .playstoreLink {
        font-size: 8px;
    }

}

@media all and (max-width: 499px) {
    .newsletter{
        height:480px;
        overflow-y: auto;
    }
    .title {
        width: 100%;
        margin-top: 10px;
    }

    .readyMadeNewsletterPdf {
        width: 100%;
    }

    .title>.h1 {
        font-size: 30px;
        border-top: 2px solid;
        border-bottom: 2px solid;
        font-weight: 500;
    }

    .Logo img {
        width: 100px;
    }

    .date {
        width: 100%;
    }

    .date .dateVal {
        font-weight: normal;
        font-size: 15px;
    }

    .subContainer {
        flex-wrap: wrap;
    }

    .theFutureTitle {
        border-bottom: 2px solid;
        font-size: 18px;

    }

    .playstoreImg {
        width: 20px;
        height: 20px;
    }

    .playstoreLink {
        font-size: 10px;
    }
}