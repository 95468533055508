.license {
    width: 100%;
    border:none;
    margin: 0px auto 20px auto;
  }
  
  .license tr > th {
    background-color: #2171b9;
    color: white;
    text-align: center;
  }
tbody {
    border:none
}
 
  /* th {
  border: 1px solid #bfbfbf;
  } */

  th:first-child {
    border:none;
    border-radius:5px 0 0 0px;
  
  }
  .license,
  th:last-child {
    border:none;
    border-radius:0px 5px 0px 0px;

  }
  .license tbody  {
    border-radius: 0 0 5px 5px;
  }
 
  
  .license tr:last-child td:first-child {
border-bottom-right-radius: 5px;
  
}
  .license tbody tr:last-child td:last-child {
    border-bottom-right-radius: 5px;
  }
  .license,tr:last-child td:first-child {
  
    border-bottom-left-radius: 5px;
  }
  thead[class*="ant-table-thead"] th:not(:first-child) {
    background-color: hsl(211deg 75% 48%) !important;
  }
  
  .license th {
    text-align: center;
    padding: 0.25rem 0.5rem;
  }
  .license td {
    padding: 0.25rem 0.5rem;
  }
  .license tr {
    height: 40px;
  }
  
  .planDetailsModalDiv {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  .planDetailsModalDiv p:first-child {
    font-size: 18px;
    font-weight: 600;
    width: 170px;
  }
  .planDetailsModalDiv p:nth-child(2) {
    font-size: 16px;
    font-weight: 400;
  }
  