@keyframes blink {
    0% {
      background-color: #7ebcf1;
      border-radius: 5px;
    }
    50% {
      background-color: transparent;
    }
    100% {
      background-color: #7ebcf1;
      border-radius: 5px;
    }
  }
  .blinkingEffect {
    animation: blink 1.5s infinite;
  }
