.tabContainer{
    display: flex;
    width: 100%;
}

.addressBox {
    width: 350px;
    color: rgb(8, 81, 143);
    font-size: 18px;
    font-weight: bold;
    font-family: sans-serif;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

.needToKnowMain{
    width: 1150px;
}

@media screen and (max-width: 1309px) {

    .needToKnowMain{
        width: 90%;
    }

    .addressBox {
        width: 350px;
        font-size: 17px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
}

@media screen and (max-width: 1250px) {
}

@media screen and (max-width: 1115px) {
}

@media screen and (max-width: 1000px) {
    .tabContainer{
        display: flex;
        width: 100%;
        position: initial;
    }
    
    .needToKnowMain{
        width: 98%;
    }

    .addressBox {
        width: 250px;
        font-size: 15px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
}

@media screen and (max-width: 600px) {
    .addressBox {
        width: 150px;
        font-size: 12px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
}


