.loading-container{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.reminderContainer {
  height: calc(100vh - 175px);
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  overflow: auto;
  padding: 0rem 1rem;
}
.ant-tooltip-inner {
  background-color: #ffcc00 !important;
  color: #333 !important;
}
@media only screen and (max-device-width: 1088px) {
  .reminderContainer {
    height: calc(100vh - 250px);
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    overflow: auto;
    padding: 0rem 1rem;
  } 
}
@media only screen and (max-device-width: 499px) {
  .reminderContainer {
    height: calc(100vh - 230px);
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    overflow: auto;
    padding: 0rem 1rem;
  }
 
  .landing_page_date{
    text-align: center;
    position: relative;
    padding-bottom: -40px;
    font-size: small;
  }
}
@media only screen and (max-device-width: 1200px) and (min-device-width: 480px) {
    .landing_page_date{
      position: relative;
      text-align: center;
      font-size: medium;
      bottom: 50px;
    }
  }

  /* @media only screen and (max-device-width: 760px) {

  } */
div[contenteditable="true"] img:hover {
  cursor: pointer;
}
