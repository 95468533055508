.statusWrapper{
    padding: 0;
    border-radius: 3px;
    width: 150px;
    text-align: center;
    font-size: 1.05rem !important;
    font-weight: 600 !important;
    font-family: "Roboto", sans-serif;
    letter-spacing: 0.5px;
}

.statusMenuIcon{
    font-size: 1rem;
}


.homeStatusWrapper{
    padding: 0;
    border-radius: 3px;
    width: 130px;
    text-align: center;
    font-size: 0.8rem !important;
    font-weight: 600 !important;
    font-family: "Roboto", sans-serif;
    letter-spacing: 0.5px;
}

.offerType{
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-size: 1.1rem;
    cursor: pointer;
    font-family: "Roboto", sans-serif;
    font-weight: 600;
    color: rgb(96, 96, 96);
    padding-left: 30px;
}

.offerHeader{
    font-size: 1.5rem;
    font-family: "Roboto", sans-serif;
    font-weight: 600;
    text-align: center;
    padding: 20px 0px;
}

.offerType > div:hover{
    background: #74b2ec;
    padding: 0px 8px;
    border-radius: 5px;
}

@media screen and (max-width: 1025px) {
    .statusWrapper{
        width: 120px;
    }

    .statusMenuIcon{
        font-size: 0.9rem;
    }

    .homeStatusWrapper{
        width: 150px;
    }
}
@media screen and (max-width: 400px) {
    .statusWrapper{
        display: flex;
        justify-content: center;
    }

    .homeStatusWrapper {
        display: flex;
        justify-content: center;
    }
}
