.Customlbl {
  font-size: 16px;
  color: gray;
  font-weight: 500;
  margin-bottom: 5px;
}
.formRow {
  margin-top: 15px;
}
.OIDraggerWrapper {
  width: 90%;
  height: 250px;
  margin: auto;
  text-align: center;
  margin-bottom: 80px;
}
.cheboxWrapperDiv{
  display: flex;
  justify-content: flex-start;
  align-items:center;
}
.otherInputWrapper{
  margin-left: 10px;
}
.validationMessage {
  display: none;
  color: red;
  font-size: 12px;
}

.Customlbl + input:invalid + .validationMessage {
  display: inline;
}

.Customlbl + input:valid + .validationMessage {
  display: none;
}

@media screen and  (max-width: 992px) {
  /* Styles for medium screens */
  .OIDraggerWrapper {
    width: 95%;
    height: 230px;
    margin-bottom: 100px;
  }
  .cheboxWrapperDiv{
    flex-direction: column;
    align-items: flex-start;
  }
  .otherInputWrapper{
    margin-left: 0px;
    margin-top: 5px;
    width: 100%;
  }
}

@media screen and (max-width: 576px) {
  /* Styles for small screens */
  .OIDraggerWrapper {
    width: 100%;
    height: 200px;
    margin-bottom: 100px;
  }
}
